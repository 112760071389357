import React, {useEffect} from 'react';
import styled from "styled-components";
import {DividerWithText} from "../../../components/ui/LogoWall/DividerWithText.jsx";
import {Paragraph} from "../../../components/ui/typography/index.js";
import {ButtonComponent} from "../../../components/ui/buttons/index.js";
import {removeBookingId} from "../js/utils/storage.js";
import {extractColorCodeFromCssVariable} from "../../themes/js/utils.js";


const EventDatesContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    max-width: 650px;
    //padding: 20px;
`

const EventDate = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    padding: 10px;
    place-items: center;
    border-radius: var(--border-radius-default);
    background: var(--primary-colour);
    box-shadow: 2px 5px 10px rgba(0,0,0,0.1), -7px -3px 20px rgba(0,0,0,0.1);
    border: 1px solid var(--primary-colour);
    cursor: pointer;
    text-align: center;


    @media screen and (min-width: 768px){
        min-width: 400px;

        p {
            text-align: center;
        }
    }
`

const Dates = ({ dates, setProductsByDate, setSelectedDate, selectedDate, setSelectedTime, setTimes, selectedTime, times }) => {
    if (!dates) return null;

    useEffect(() => {
        setProductsByDate([])
    }, [selectedTime, selectedDate])

    useEffect(() => {
        // Auto-select the date if there's only one
        if (uniqueDates.length === 1) {
            handleClickDate(uniqueDates[0]);
        }
    }, [dates]);

    const handleClickDate = (date) => {
        setSelectedDate(date);
        const timesForSelectedDate = dates.filter(d => d.startDate === date.startDate);
        setTimes(timesForSelectedDate);
    };

    const handleClickTime = (time) => {
        setSelectedTime(time);
    };

    // Remove duplicate dates based on startDate
    const uniqueDates = dates.reduce((acc, current) => {
        const x = acc.find(item => item.startDate === current.startDate);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);

    const handleRestart = () => {
        removeBookingId();
        setSelectedDate(null);
        setSelectedTime(null);
        setTimes([]);
        // Reset to initial state with all dates
        const uniqueDates = dates.reduce((acc, current) => {
            const x = acc.find(item => item.startDate === current.startDate);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        if (uniqueDates.length === 1) {
            handleClickDate(uniqueDates[0]);
        }
    };

    return (
        <EventDatesContainer>

            {selectedDate && (
                <>
                    <DividerWithText text={`SELECTED DATE`} />

                    <EventDate  style={{border: `1px solid white`}}>
                        <Paragraph>{selectedDate.startDate}</Paragraph>
                    </EventDate>
                </>

            )}

            {selectedTime && (
                <>
                    <DividerWithText text={`SELECTED TIME`} />

                    <EventDate style={{border: '1px solid white'}}>
                        <Paragraph>{selectedTime.startTime} - {selectedTime.endTime}</Paragraph>
                    </EventDate>
                </>

            )}

            {/* Display "PICK A DATE" section only if there are multiple dates */}
            {!selectedDate && uniqueDates.length > 1 && (
                <>
                    <DividerWithText text={`PICK A DATE`} />
                    {uniqueDates.map((date, index) => (
                        <EventDate style={{border: '1px solid white'}} key={index} onClick={() => handleClickDate(date)}>
                            <Paragraph>{date.startDate}</Paragraph>
                        </EventDate>
                    ))}
                </>
            )}

            {/* Display all the times available for a specific date when a date has been selected */}

            { selectedDate && !selectedTime && <DividerWithText text={`PICK A TIME`} />}

            {selectedDate && !selectedTime && (
                times.map((time, index) => {

                    const style = {
                        border: '1px solid white',
                        borderColor: selectedTime === time ? 'var(--accent-colour)' : 'var(--primary-colour)',
                    }

                    return (
                        <EventDate key={index} style={style} onClick={() => handleClickTime(time)}>
                            <Paragraph style={{margin: 0}}>{time.startTime} - {time.endTime}</Paragraph>
                        </EventDate>
                    );
                })
            )}

            {selectedDate && (
                <ButtonComponent
                    variant={'tertiary'}
                    text={'Restart'}
                    onClick={handleRestart}
                />
            )}
        </EventDatesContainer>
    );
};


export default Dates;



