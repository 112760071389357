import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {useApiQuery} from '../../../../hooks/customHooks.js';
import {FloatingNav} from '../../../../layout/components/FloatingNav/FloatingNav.jsx';
import {Loading} from '../../../../components/ui/Loading.jsx';
import {convertToCurrency} from '../../../../js/helpers/helpers.js';
import {FaCheckCircle, FaTag, FaTimesCircle} from 'react-icons/fa';
import {ButtonComponent} from '../../../../components/ui/buttons/ButtonComponent.jsx';
import {IoCartOutline} from 'react-icons/io5';
import {API_URL, scrollToElement, STRIPE_PUBLISHABLE_KEY} from '../../../../js/Helper.js';
import {DividerWithText} from '../../../../components/ui/LogoWall/DividerWithText.jsx';
import Accordian from '../../../../components/ui/Accordian/Accordian.jsx';
import ReactPlayer from 'react-player';
import {AiFillPlayCircle} from 'react-icons/ai';
import {useDrawer} from '../../../../js/hooks/context/DrawerContext.jsx';
import PackForm from '../../../packs/components/PackForm.jsx';
import {AuthContext} from '../../../authentication/index.js';
import {Paragraph} from '../../../../components/ui/typography/index.js';
import {useEcommerce} from '../../js/context/EcommerceContext.jsx';
import useHandleClickCheckout from '../../js/hooks/useHandleClickCheckout.jsx';
import {Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {toast} from 'react-toastify';
import StripePaymentForm from '../../services/stripe/components/StripePaymentForm.jsx';
import {useCheckout} from '../../js/context/CheckoutContext.jsx';
import axios from 'axios';
import {extractColorCodeFromCssVariable, updateTheme} from '../../../themes/js/utils.js';
import {Divider} from '../../../../components/ui/Divider.jsx';
import ImpactTitle from '../../../../components/ui/typography/ImpactTitle.jsx';
import {
  useMutation,

} from '@tanstack/react-query'

const PageContainer = styled.div`
  max-width: 100vw;
  overflow-x: hidden;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -20px;
  position: relative;
  z-index: 3;
  gap: 20px;
  

  @media (min-width: 768px) {
    padding: 40px;
    margin-top: -40px;
  }

  @media (min-width: 1024px) {
    padding: 60px;
    margin-top: -60px;
  }
`;

const HeroContainer = styled.section`
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    // border: 1px solid red;
    margin-bottom: 20px;
  `;

  const SlideImage = styled.div`
    height: 100%;
    width: 100%;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease-in-out;
    cursor: pointer; // Add this to indicate it's clickable
  `;

  const GradientOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 10%, var(--primary-colour));
    z-index: 1;
  `;

  const SlideText = styled.h1`
    position: absolute;
    bottom: 20%; /* Position it in the lower third */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    color: white;
    font-size: 4em;
    z-index: 2;
    text-align: center; /* Center text */
    text-transform: uppercase;
    background: var(--accent-colour);
    font-weight: 900;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  `;

  const SlideButton = styled.button`
    position: absolute;
    top: 50%;
    ${props => props.direction === 'left' ? 'left: 10px;' : 'right: 10px;'}
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
  `;

  const MagnifyButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
  `;

  const FullscreenContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const FullscreenImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  `;

  const FullscreenButton = styled.button`
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
  `;


/**
 * Hero Section
 * @param {*} product 
 * @returns 
 */
const HeroSection = ({ product }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);

  console.log('product',product);

  const slides = product?.files?.filter((file) => file.name === 'slider');

  // const slides = [
  //   { image: `https://picsum.photos/1080/1920`, text: product.name },
  //   { image: `https://picsum.photos/1080/1921`, text: 'Slide 2 Text' },
  //   { image: `https://picsum.photos/1080/1922`, text: 'Slide 3 Text' },
  // ];

  const nextSlide = () => setCurrentSlide((prev) => (prev + 1) % slides.length);
  const prevSlide = () => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);

  
  if(slides.length === 0) return null;

  return (
    <HeroContainer>
      {slides.map((slide, index) => (
        <SlideImage 
          key={index} 
          image={slide.cdnUrl}
          style={{ 
            position: 'absolute',
            transform: `translateX(${100 * (index - currentSlide)}%)`,
          }}
          onClick={() => setIsFullscreen(true)}
        />
      ))}
      <GradientOverlay />
      <SlideText>{'music video production'}</SlideText>
      {slides.length > 1 && (
        <>
          <SlideButton direction="left" onClick={prevSlide}>←</SlideButton>
          <SlideButton direction="right" onClick={nextSlide}>→</SlideButton>
        </>
      )}
      <MagnifyButton onClick={() => setIsFullscreen(!isFullscreen)}>🔍</MagnifyButton>
      {isFullscreen && (
        <FullscreenContainer>
          <FullscreenImage 
            src={slides[currentSlide].cdnUrl} 
            onClick={(e) => e.stopPropagation()}
          />
          <FullscreenButton onClick={() => setIsFullscreen(false)} style={{top: 10, right: 10}}>Close</FullscreenButton>
          {slides.length > 1 && (
            <>
              <FullscreenButton onClick={prevSlide} style={{left: 10, top: '50%', transform: 'translateY(-50%)'}}>←</FullscreenButton>
              <FullscreenButton onClick={nextSlide} style={{right: 10, top: '50%', transform: 'translateY(-50%)'}}>→</FullscreenButton>
            </>
          )}
        </FullscreenContainer>
      )}
    </HeroContainer>
  );
};

const ProductInfoContainer = styled.section`
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 10px;
    color: var(--text-colour);
    background-color: var(--secondary-colour);
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 2;
  `;

  const ProductName = styled.h1`
    font-size: 2.5em;
    margin-bottom: 15px;
    color: var(--accent-colour);
  `;

  const ProductDescription = styled.p`
    margin-bottom: 25px;
    line-height: 1.6;
    font-size: 1.1em;

    p {
      margin-bottom: 10px;
    }
  `;

  const ProductMetaContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  `;

  const MetaItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    font-size: 1.1em;
    width: fit-content;
  `;

  const PriceTag = styled(MetaItem)`
    font-size: 1.2em;
    font-weight: bold;
    color: var(--accent-colour);
    background-color: rgba(191, 181, 147, 0.2);
  `;

  const StockIndicator = styled.span`
    color: ${props => props.inStock ? 'var(--success-colour)' : '#F44336'};
    font-weight: bold;
  `;

  const OffersSection = styled.div`
    position: relative;
    padding: 0;
    margin: 20px 0;
  `;

  const OffersScrollContainer = styled.div`
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 10px 0;

    &::-webkit-scrollbar {
      display: none;
    }
  `;

  const OfferCard = styled.div`
    display: inline-block;
    background: ${props => props.isSelected ? 'var(--accent-colour)' : 'var(--secondary-colour)'};
    border: 2px solid var(--accent-colour);
    border-radius: 10px;
    padding: 10px 15px;
    margin-right: 15px;
    max-width: 250px;
    cursor: pointer;
    transition: all 0.3s ease;
    word-wrap: break-word;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }

    ${props => props.isSelected && `
      color: var(--secondary-colour);
      transform: translateY(-5px);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    `}
  `;

  const OfferName = styled.h4`
    font-size: 1.1em;
    color: ${props => props.isSelected ? 'var(--secondary-colour)' : 'var(--accent-colour)'};
    margin-bottom: 5px;
    text-wrap: balance;
  `;

  const OfferDescription = styled.p`
    font-size: 0.9em;
    color: ${props => props.isSelected ? 'var(--secondary-colour)' : 'var(--text-colour)'};
    margin-bottom: 5px;
  `;

  const OfferValue = styled.span`
    font-weight: bold;
    color: ${props => props.isSelected ? 'var(--secondary-colour)' : 'var(--success-colour)'};
  `;

  const PriceDisplay = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
  `;

  const OriginalPrice = styled.span`
    text-decoration: ${props => props.hasDiscount ? 'line-through' : 'none'};
    color: ${props => props.hasDiscount ? '#F44336' : 'inherit'};
  `;

  const DiscountedPrice = styled.span`
    font-weight: bold;
    color: #4CAF50;
  `;

  // Add these styled components near other styled components
  const CustomFormInputs = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 15px;
    margin: 20px 0;
  `;

  const FormInput = styled.input`
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    color: white;
    font-size: 16px;
    border: 1px solid ${extractColorCodeFromCssVariable('accent-colour')};
    border-color: ;

    &::placeholder {
      color: white;
    }

    &:focus {
      outline: none;
      border-color:  ${extractColorCodeFromCssVariable('accent-colour')};
    }
  `;

  // Add these styled components
  const ReviewFormContainer = styled.div`
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    max-height: ${props => props.isOpen ? '600px' : '0px'};
    opacity: ${props => props.isOpen ? '1' : '0'};
    transform: translateY(${props => props.isOpen ? '0' : '-20px'});
    transition: all 0.3s ease-out;
  `;

  /**
   * Product Info Section
   * @param {*} product 
   * @returns 
   */
const ProductInfoSection = ({ product, offers }) => {
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const { openDrawer } = useDrawer();
  const { addToBasket, basket, setConfig, emptyBasket } = useEcommerce();
  const {
    checkout,
    setCheckout,
    setTotal,
    setFees,

    setStripe,
    setQuestions,
    checkoutQuestions
  } = useCheckout();
  const { handleClickCheckout, checkoutLoading } = useHandleClickCheckout();
  const { user, setUser, isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    business_name: '',
    full_name: user ? `${user.first_name} ${user.last_name}` : '',
    email: user?.email || ''
  });
  const [pendingCheckout, setPendingCheckout] = useState(false);

  const handleInputChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  // Add this useEffect to watch for basket changes
  useEffect(() => {
    const processCheckout = async () => {
      if (pendingCheckout && basket.orderItems?.length > 0) {
        setPendingCheckout(false);
        setIsProcessing(true);
        const clickCheckout = await handleClickCheckout(null, product.business_id, 'return');
        console.log('clickCheckout', clickCheckout);
        if(clickCheckout?.status !== 'success') {
          console.log(clickCheckout);
          toast.error(clickCheckout.data.error_message || 'There has been an error');
          setIsProcessing(false);
          return;
        }

        axios.get(`${API_URL}/checkout/${clickCheckout.basket.id}`)
          .then(response => {
            const data = response.data;
            
            const formatFees = data.checkout.order_items.flatMap(orderItem => {
                const formattedFees = [];

                // Collect fees for the main order item
                if (orderItem.order_fees && orderItem.order_fees.length > 0) {
                    orderItem.order_fees.forEach(fee => {
                        formattedFees.push({
                            fee_type: fee.product_fee.name,
                            amount: fee.amount || 0
                        });
                    });
                }

                // Collect fees for the addons
                if (orderItem.addons && orderItem.addons.length > 0) {
                    orderItem.addons.forEach(addon => {
                        if (addon.order_fees && addon.order_fees.length > 0) {
                            addon.order_fees.forEach(fee => {
                                formattedFees.push({
                                    fee_type: fee.product_fee.name,
                                    amount: fee.amount || 0
                                });
                            });
                        }
                    });
                }

                return formattedFees;
            });


            if(data?.status === 'success') {
                if(data?.checkout) setCheckout(data?.checkout)
                if(data?.fees) setFees(formatFees);
                if(data?.total) setTotal(data?.totals);
                if(data?.stripe) setStripe(data?.stripe);
                if(data?.event) setEvent(data?.event);
                if(data?.checkoutQuestions) setQuestions(data?.checkoutQuestions);
                setPaymentIntent(data?.stripe?.intent);
                if(data?.config) setConfig(data?.config);
                // updateTheme(data?.event?.theme)
               
            }

            setShowPaymentForm(true);
          })
          .catch(error => {
            console.error('Error fetching checkout:', error);
            toast.error('Failed to load checkout details');
            setIsProcessing(false);
          });
      }
    };

    processCheckout();
  }, [basket, pendingCheckout]);

  const handleLockInOffer = () => {
    if (!selectedOffer) return;
    const { files, faqs, reviews, offers, ...productWithoutArrays } = product;
    productWithoutArrays.quantity = 1;
    productWithoutArrays.paymentOption = 'deposit';
    productWithoutArrays.offerId = selectedOffer.id;
    productWithoutArrays.sub_total = product.price;

    if(!formData.full_name || !formData.email) {
      toast.warn('Please fill in all required fields');
      return;
    }
    
    // Add the form data to the product object
    productWithoutArrays.customer_details = {
      business_name: formData.business_name,
      full_name: formData.full_name,
      email: formData.email
    };



    emptyBasket();

    addToBasket(productWithoutArrays, 1, selectedOffer);
    setPendingCheckout(true);
  };

  const handlePayment = async (stripe, elements) => {
    setIsProcessing(true);

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://example.com/order/123/complete",
      },
    });

    if (result.error) {
      console.log(result.error.message);
      toast.error(result.error.message);
    } else {
      toast.success('Payment successful');
    }

    setIsProcessing(false);
  };

  const ProductEnquiry = () => {
    openDrawer(<PackForm 
      name='Product Enquiry'
      endpointUrl='product-purchase-enquiries'
      inputs={
        [
          { 
            name: 'full_name', 
            label: 'Full Name', 
            type: 'text', 
            placeholder: 'Enter your name', 
            required: true,
            value: user ? `${user.first_name} ${user.last_name}` : undefined
          },
          { 
            name: 'email', 
            label: 'Email Address', 
            type: 'email', 
            placeholder: 'Enter your email', 
            required: true,
            value: user?.email
          },
          { 
            name: 'phone', 
            label: 'Phone Number', 
            type: 'tel', 
            placeholder: 'Enter your phone number', 
            required: false,
            value: user?.phone
          }, 
          { name: 'product_id', type: 'hidden', value: product.id },
          {name: 'referrer', type: 'hidden', value: 'product-page'},
          {name: 'quantity', type: 'hidden', value: 1},
          { name: 'enquiry', label: 'Enquiry', type: 'textarea', placeholder: 'Provide us with some details about your enquiry', required: false }
        ]
      } />);
  }

  const showPaymentModal = () => {
    addToBasket(product, 1, selectedOffer);
    
  }

  const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
    const discountAmount = originalPrice * (discountPercentage / 100);
    return originalPrice - discountAmount;
  }

  const handleOfferClick = (offer) => {
    setSelectedOffer(offer);
  }

  const discountedPrice = selectedOffer 
    ? calculateDiscountedPrice(product.price, selectedOffer.value)
    : null;


  return (
    <>
        {/* <InstructionTitle title={'Product Information'} style={{marginBottom: '-25px', zIndex: 1, margin: '0 auto', textTransform: 'uppercase'}} /> */}
        <DividerWithText text='Product Information' style={{marginBottom: '20px'}} />

        <ProductInfoContainer id="info">
          {/* <ProductName>{product.name}</ProductName> */}
          <ImpactTitle subHeader={product.business.name} main={product.name} />
          
          <ProductDescription id='description' dangerouslySetInnerHTML={{ __html: product.description }} />
        </ProductInfoContainer>
          
          {offers && offers.length > 0 && (
            <OffersSection id='offers'>
              <DividerWithText 
                text='Available Offers' 
                style={{
                  marginBottom: '30px',
                  textTransform: 'uppercase',
                  position: 'sticky',
                  top: 0,
                  background: 'var(--primary-colour)',
                  zIndex: 1
                }} 
              />
              <OffersScrollContainer>
                {offers.sort((a, b) => b.value - a.value).map((offer) => (
                  <OfferCard 
                    key={offer.id} 
                    onClick={() => handleOfferClick(offer)}
                    isSelected={selectedOffer && selectedOffer.id === offer.id}
                  >
                    <OfferName isSelected={selectedOffer && selectedOffer.id === offer.id}>
                      {offer.name}
                    </OfferName>
                    <OfferDescription isSelected={selectedOffer && selectedOffer.id === offer.id}>
                      {offer.description}
                    </OfferDescription>
                    <OfferValue isSelected={selectedOffer && selectedOffer.id === offer.id}>
                      {offer.value}% OFF
                    </OfferValue>
                    <OfferDescription>
                      {offer?.redemptionsLeft} Available
                    </OfferDescription>
                  </OfferCard>
                ))}
              </OffersScrollContainer>
            </OffersSection>
          )}

          <ProductInfoContainer>
            <ProductMetaContainer id='meta'>
            <PriceDisplay>
              <PriceTag>
                <FaTag />
                <OriginalPrice hasDiscount={!!selectedOffer}>
                  {convertToCurrency(product.price)}
                </OriginalPrice>
                {discountedPrice && (
                  <DiscountedPrice>
                    {convertToCurrency(discountedPrice)}
                  </DiscountedPrice>
                )}
              </PriceTag>
            </PriceDisplay>
            <MetaItem>
              {product.remaining > 0 ? <FaCheckCircle color="var(--success-colour)" /> : <FaTimesCircle color="var(--error-colour)" />}
              <StockIndicator id='stock' inStock={product.remaining > 0}>
                {product.remaining > 0 ? 'Available' : 'Unavailable'}
              </StockIndicator>
            </MetaItem>
            </ProductMetaContainer>
            {
              selectedOffer && product.remaining > 0 && (
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px', marginTop: '20px'}}>
                  <Paragraph>Lock in this price and secure your <strong>{selectedOffer.name}</strong> offer by paying a deposit of<strong> only {convertToCurrency(discountedPrice / 2)}</strong> now.</Paragraph>
                  
                  {!showPaymentForm ? (
                    <>
                      <Divider />

                      <CustomFormInputs>
                        <FormInput
                          type="text"
                          name="business_name"
                          placeholder="Business Name"
                          value={formData.business_name}
                          onChange={handleInputChange}
                          required
                        />
                        <FormInput
                          type="text"
                          name="full_name"
                          placeholder="Full Name"
                          value={formData.full_name}
                          onChange={handleInputChange}
                          required
                        />
                        <FormInput
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </CustomFormInputs>
                      <Divider style={{marginBottom: '20px'}}/>
                      <ButtonComponent 
                        id='lockOffer' 
                        text='Lock in Offer' 
                        icon={<IoCartOutline />} 
                        variant='solid' 
                        onClick={handleLockInOffer}
                        disabled={isProcessing || !formData.business_name || !formData.full_name || !formData.email}
                        isLoading={checkoutLoading}
                      />
                    </>
                  ) : (
                    paymentIntent && (
                      <StripePaymentForm 
                        payment_intent={paymentIntent}
                        style={{padding: '50px 0', width: '100%'}}
                        // theme={theme}
                
                        onSuccess={() => toast.success('Payment successful')}
                      />
                    )
                  )}
                </div>
              )
            }
            {/* Only show enquiry button if payment form is not visible */}
            {!showPaymentForm && (
              <ButtonComponent 
                id='enquiry' 
                text='Make Enquiry' 
                icon={<IoCartOutline />} 
                variant='outlined' 
                onClick={() => ProductEnquiry()} 
              />
            )}
          </ProductInfoContainer>
    </>
  );
};

const ReviewSectionContainer = styled.section`
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 20px 30px;
  // border: 1px solid ${extractColorCodeFromCssVariable('--accent-colour')};
  border-radius: var(--border-radius-default);
  background-color: var(--secondary-colour);

  &::-webkit-scrollbar {
    display: none;
  }
  margin: 0 -20px;
`;

const ReviewList = styled.div`
  display: inline-flex;
  gap: 20px;
  // padding: 0 20px; /* Add horizontal padding */

  /* Ensure the first review aligns with the left edge of the screen */
  &::before {
    content: '';
    padding-left: 00px;
  }

  /* Ensure the last review aligns with the right edge of the screen */
  &::after {
    content: '';
    padding-right: 20px;
  }
`;

const ReviewCard = styled.div`
  background-color: var(--secondary-colour);
  border-radius: 10px;
  padding: 15px;
  min-width: 250px;
  max-width: 350px;
  text-wrap: balance;
`;

const ReviewerName = styled.h3`
  font-size: 1.6em;
  margin-bottom: 10px;
`;

const ReviewText = styled.p`
  margin-bottom: 10px;
`;

const StarRating = styled.div`
  color: ${extractColorCodeFromCssVariable('--accent-colour')};
`;


/**
 * Review Section
 * @param {*} product 
 * @returns 
 */
const ReviewSection = ({ product }) => {
  const [reviews, setReviews] = useState(product?.reviews || []);
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [reviewFormData, setReviewFormData] = useState({
    full_name: '',
    business_name: '',
    rating: 0,
    review: '',
    rating_type: 'product',
    type: 'product',
    id: product.id
  });

  const mutation = useMutation({
    mutationFn: (data) => createReview(data),
    onSuccess: (response) => {
      // Create a new review object from the form data and response
      const newReview = {
        id: response.data.id, // Assuming the API returns the new review's ID
        name: reviewFormData.full_name,
        business_name: reviewFormData.business_name,
        rating: reviewFormData.rating,
        review: reviewFormData.review,
        created_at: new Date().toISOString()
      };

      // Add the new review to the beginning of the reviews array
      setReviews(prevReviews => [newReview, ...prevReviews]);
      
      // Reset the form
      setReviewFormData({
        full_name: '',
        business_name: '',
        rating: 0,
        review: '',
        rating_type: 'product',
        type: 'product',
        id: product.id
      });
      
      // Hide the form
      setShowReviewForm(false);
      
      toast.success('Review submitted successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || error?.message || error || 'There has been an error');
    }
  });

  const createReview = (data) => {
    return axios.post(`${API_URL}/reviews`, data);
  }

  const handleReviewInputChange = (e) => {
    setReviewFormData({ ...reviewFormData, [e.target.name]: e.target.value });
  }

  const handleRatingClick = (rating) => {
    setReviewFormData({ ...reviewFormData, rating });
  }

  const handleSubmitReview = () => {
    mutation.mutate(reviewFormData);
  }


  return (
    <div id='reviews' style={{padding: '20px'}}>
      <DividerWithText text='Customer Reviews' style={{margin: '20px 0'}} />
      {reviews && reviews.length > 0 ? (
        <ReviewSectionContainer style={{borderBottom: `1px solid ${extractColorCodeFromCssVariable('--accent-colour')}`}}>
          <ReviewList>
            {reviews.map(review => (
              <ReviewCard key={review.id}>
                <ReviewerName>{review.name}</ReviewerName>
                <ReviewText>{review.review}</ReviewText>
                <StarRating>{'★'.repeat(review.rating)}{'☆'.repeat(5 - review.rating)}</StarRating>
              </ReviewCard>
            ))}
          </ReviewList>
        </ReviewSectionContainer>
      ) : (
        <Paragraph style={{textAlign: 'center', padding: '20px'}}>
          No reviews yet
        </Paragraph>
      )}
      <ReviewSectionContainer style={{borderTop: `1px solid ${extractColorCodeFromCssVariable('--accent-colour')}`}}>
   

        <ButtonComponent 
          onClick={() => setShowReviewForm(!showReviewForm)}
          style={{margin: '20px auto', display: 'block'}}
          variant='outlined'
        >
          {showReviewForm ? 'Cancel' : 'Leave a Review'}
        </ButtonComponent>

        {showReviewForm && (
          <ReviewFormContainer isOpen={showReviewForm}>
            <CustomFormInputs>
              <FormInput
                type="text"
                name="full_name"
                placeholder="Full Name"
                value={reviewFormData.full_name}
                onChange={handleReviewInputChange}
              />
              <FormInput
                type="text" 
                name="business_name"
                placeholder="Business Name"
                value={reviewFormData.business_name}
                onChange={handleReviewInputChange}
              />
              <StarRating style={{fontSize: '24px', cursor: 'pointer'}}>
                {[1,2,3,4,5].map(star => (
                  <span 
                    key={star}
                    onClick={() => handleRatingClick(star)}
                    style={{color: star <= reviewFormData.rating ? extractColorCodeFromCssVariable('--accent-colour') : 'gray'}}
                  >
                    ★
                  </span>
                ))}
              </StarRating>
              <FormInput
                as="textarea"
                name="review"
                placeholder="Write your review here..."
                value={reviewFormData.review}
                onChange={handleReviewInputChange}
                style={{minHeight: '100px', resize: 'vertical'}}
              />
              <ButtonComponent onClick={handleSubmitReview} variant='solid' isLoading={mutation.isLoading}>
                Submit Review
              </ButtonComponent>
            </CustomFormInputs>
          </ReviewFormContainer>
        )}
      </ReviewSectionContainer>
    </div>
  )
};

const FAQSection = styled.section`
  padding: 40px 20px;
  min-width: unset;

  @media (min-width: 1024px) {
    padding: 60px;
  }
`;

const FAQTitle = styled.h2`
  font-size: 2em;
  color: var(--accent-colour);
  margin-bottom: 30px;
  text-align: center;
`;

// New Gallery styled components
const GallerySection = styled.section`
  padding: 20px 0;
`;

const GalleryScroll = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar { 
    display: none;
  }

  /* Add padding to the container */
  padding: 0 20px;
  border-radius: 10px;

`;

const GalleryItem = styled.div`
  flex: 0 0 auto;
  width: 100%;
  scroll-snap-align: start;

  &:not(:only-child) {
    width: 300px;
    margin-right: 20px;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */

`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  
`;

const ThumbnailContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const PlayIcon = styled(AiFillPlayCircle)`
  position: absolute;
  font-size: 50px;
  color: white;
  opacity: 0.8;
`;

// Video Component
const VideoComponent = ({ data }) => {
  const [playing, setPlaying] = useState(false);

  const handlePlay = () => {
    setPlaying(true);
  };

  return (
    <VideoContainer>
      <StyledReactPlayer
        url={data.cdnUrl}
        width="100%"
        height="100%"
        controls
        playing={playing}
        onPause={() => setPlaying(false)}
      />
      {!playing && (
        <ThumbnailContainer onClick={handlePlay}>
          {/*<Thumbnail src={data.thumbnail} alt="Video Thumbnail" />*/}
          <PlayIcon />
        </ThumbnailContainer>
      )}
    </VideoContainer>
  );
};

// Move stripePromise outside component
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

// Add new StripeWrapper component
const StripeWrapper = ({ clientSecret, children }) => {
  const options = {
    clientSecret,
    // Add any additional Stripe options here
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

// Create a styled container for the payment form
const PaymentFormContainer = styled.form`
  width: 100%;
  max-width: 500px; // Optional: set a max-width if needed
  
  .StripeElement {
    width: 100%;
    margin-bottom: 20px;
  }
`;

// Update the PaymentForm component
const PaymentForm = ({ clientSecret, onPayment, isProcessing, amount, user }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    
    onPayment(stripe, elements);
  };

  return (
    <PaymentFormContainer onSubmit={handleSubmit}>
      <PaymentElement 
        stripe={stripePromise}
        options={{
          labels: "floating",
          appearance: {
            theme: 'night',
            labels: "floating",
            variables: {
              fontWeightNormal: '500',
              borderRadius: '12px',
              colorBackground: 'var(--primary-colour)',
              colorPrimary: 'var(--accent-colour)',
              accessibleColorOnColorPrimary: '#1A1B25',
              colorText: 'white',
              colorTextSecondary: 'white',
              colorTextPlaceholder: '#727F96',
              tabIconColor: 'white',
              logoColor: 'dark',
              gridRowSpacing: '10px',
            },
          }
        }}
      />
      <ButtonComponent 
        id='pay' 
        text={`Pay ${convertToCurrency(amount)} now`}
        variant='solid' 
        type="submit" 
        disabled={!stripe || isProcessing}
        isLoading={isProcessing}
        style={{marginTop: '20px', width: '100%', background: 'var(--success-colour)'}}
      />
    </PaymentFormContainer>
  );
};

const ProductPage = () => {
  const { id } = useParams();
  const { data: product, isLoading, error } = useApiQuery(`products/${id}?with=fileLinks,reviews,faqs,offers,business`);
  const offers = product?.offers;

  const searchParams = new URLSearchParams(location.search);
  const section = searchParams.get('section');

  if(product?.business) {
    updateTheme(product.business.theme);
  }

  if (section) {
    scrollToElement(section);
  }


  const floatingNavItems = [
    { name: 'Info', destination: `?section=info`, icon: 'IoInformationCircleSharp', position: 1 },
    ...(offers ? [{ name: 'Offers', destination: `?section=offers`, icon: 'IoGiftSharp', position: 4 }] : []),
    { name: 'Reviews', destination: `?section=reviews`, icon: 'IoStarSharp', position: 2 },
    { name: 'FAQ', destination: `?section=faq`, icon: 'IoCardSharp', position: 3 },
  ];

  // FAQ data
  const faqData = product?.faqs?.map(faq => ({
    placeholder: faq.question,
    description: faq.answer
  }));

  // Sample gallery data
  const galleryData = product?.files?.filter((file) => file.name === 'videoGallery');

  if (isLoading) return <Loading />;
  if (error) return <div>Error: {error.message}</div>;

  console.log('galleryData',galleryData);

  return (
    <PageContainer>
      <HeroSection product={product} />
        
        <ContentSection>
        <GallerySection>
          {/* <DividerWithText text='Gallery' style={{marginBottom: '20px'}} /> */}
          {
            galleryData.length > 0 && (
              <GalleryScroll>
                {galleryData.map((item, index) => (
                  <GalleryItem key={index}>
                    <VideoComponent data={item} />
              </GalleryItem>
            ))}
              </GalleryScroll>
            )
          }
        </GallerySection>
          <ProductInfoSection offers={offers} product={product} />

          <ReviewSection product={product} />

          <DividerWithText text='Business Information' style={{marginBottom: '20px'}} />

          <ContentSection style={{textAlign: 'center'}}>
            <h3>{product.business.name}</h3>
            <p>{product.business.description}</p>
          </ContentSection>
      

        {faqData.length > 0 && (
          <FAQSection id="faq">
            <DividerWithText text='Frequently Asked Questions' style={{marginBottom: '20px'}} />
            <Accordian data={faqData} accordionStyle={{
              backgroundColor: 'var(--primary-colour)',
              color: 'var(--text-colour)',
              border: '1px solid var(--text-colour)',
              marginBottom: '10px',
            }} />
          </FAQSection>
        )}
        </ContentSection>

        <FloatingNav items={floatingNavItems} />
    </PageContainer>
  );
};

export default ProductPage;
