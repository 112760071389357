import React, {useEffect} from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import {HelmetProvider} from 'react-helmet-async'
import {BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from 'react-router-dom'
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"
import {ReactQueryDevtools} from "@tanstack/react-query-devtools"
import * as Sentry from "@sentry/react";
// import BugsnagPluginReact from '@bugsnag/plugin-react'
// import BugsnagPerformance from '@bugsnag/browser-performance'
// import ErrorFallback from './pages/Error.jsx'
import {AuthProvider} from './features/authentication/index.js'
import {ModelsProvider} from './hooks/contexts/ModelsContext.jsx'
import {EcommerceProvider} from './features/ecommerce/js/context/EcommerceContext.jsx'
import {CardProvider} from './features/cards/js/context/CardContext.jsx'
import {DrawerProvider} from './js/hooks/context/DrawerContext.jsx'
import {CheckoutProvider} from './features/ecommerce/js/context/CheckoutContext.jsx'
import {OrderProvider} from './features/ecommerce/orders/hooks/OrderContext.jsx'
import {EventProvider} from './features/events/js/hooks/index.js'
import ErrorFallback from "./pages/Error.jsx";
import { handleError } from './services/errorHandling.js'
import {APP_ENV} from "./js/Helper.js";
// import {BUILD_VERSION} from "./js/Helper.js";


const queryClient = new QueryClient();
Sentry.init({
  dsn: "https://99faaea4657885a3900f93c180eee170@o4507242805788672.ingest.de.sentry.io/4507242907435088",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration(),
  ],
  environment: APP_ENV || 'local',
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/, /^http:\/\/localhost:5173/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event, hint) {
    const error = hint.originalException;
    if (error && error.response) {
      event.extra = {
        ...event.extra,
        apiResponse: error.response.data,
        apiStatus: error.response.status,
      };
    }
    return event;
  },
});

window.addEventListener('unhandledrejection', (event) => {
  handleError(event.reason, 'HIGH');
});

window.addEventListener('error', (event) => {
  handleError(event.error, 'HIGH');
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Sentry.ErrorBoundary
        fallback={({ error, resetError }) => (
          <ErrorFallback error={error} resetErrorBoundary={resetError} />
        )}
      >
        <BrowserRouter>
          <AuthProvider>
            <ModelsProvider>
              <HelmetProvider>
                <EventProvider>
                  <EcommerceProvider>
                    <OrderProvider>
                      <CheckoutProvider>
                        <CardProvider>
                          <DrawerProvider>
                            <CheckoutProvider>
                              <App />
                            </CheckoutProvider>
                          </DrawerProvider>
                        </CardProvider>
                      </CheckoutProvider>
                    </OrderProvider>
                  </EcommerceProvider>
                </EventProvider>
              </HelmetProvider>
            </ModelsProvider>
          </AuthProvider>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>
)
