import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Title = styled.h1`
    font-size: 5rem;
    line-height: 1;
    font-weight: 900;
    text-transform: uppercase;
    color: var(--accent-colour);
`

const SubTitle = styled.h2`
    font-weight: lighter;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.2rem;
`

const ShortTitle = ({ title, subtitle, style}) => {
    return (
        <Container style={style.container}>
            <Title style={style.title}>{title}</Title>
            <SubTitle style={style.subtitle}>{subtitle}</SubTitle>
        </Container>
    );
};

ShortTitle.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    style: PropTypes.object
};

export default ShortTitle;