import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import { ProductClickProvider } from "../../ecommerce/products/hooks/index.js";
import { TicketItem } from "../../events/components/TicketItem.jsx";
import { InstructionTitle } from '../../../components/ui/typography/InstructionTitle.jsx';
import ImpactTitle from '../../../components/ui/typography/ImpactTitle.jsx';

const CollectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
`;

const CategoryButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const CategoryButton = styled.button`
  background: ${props => props.active ? 'var(--accent-colour)' : '#f8f9fa'};
  color: ${props => props.active ? 'white' : 'black'};
  border: none;
  border-radius: var(--border-radius-default);
  padding: 8px 16px;
  height: 40px;
  width: 100px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.active ? '#0056b3' : '#e9ecef'};
  }
`;

const ProductListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

/**
 * @param {object} data - The data object containing the product list.
 * @param {function} onClickFunction - The function to be called when a product is clicked.
 * @param {array} selectedList - The list of selected products.
 * @returns {JSX.Element} - The ProductCollection component.
 */

const ProductCollection = ({ data, onClickFunction, selectedList }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [products, setProducts] = useState([]);

  console.log('data', data)


  useEffect(() => {
    if (data && data.product_list) {
      const categoryList = Object.keys(data.product_list);
      setCategories(categoryList);
      setSelectedCategory(categoryList[0]);
    }
  }, [data]);

  useEffect(() => {
    if (selectedCategory && data && data.product_list) {
      const sortedProducts = [...data.product_list[selectedCategory]].sort((a, b) => b.price - a.price);
      setProducts(sortedProducts);
    }
  }, [selectedCategory, data]);

  return (
    <CollectionContainer>
      {categories.length > 1 && (
        <CategoryButtonContainer>
          {categories.map((category) => (
            <CategoryButton
              key={category}
              active={category === selectedCategory}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </CategoryButton>
          ))}
        </CategoryButtonContainer>
      )}
      {/* <ImpactTitle header={'Deliverables'} /> */}
      <ProductListContainer>
        {products.map((item, index) => {

          const isSelected = selectedList?.includes(item);
          return (
            <ProductClickProvider key={index} item={item}>
              <TicketItem item={item} onClickFunction={onClickFunction} style={{background: isSelected ? 'var(--success-colour)' : null}} />
            </ProductClickProvider>
          )
        })}
      </ProductListContainer>
    </CollectionContainer>
  );
};

ProductCollection.propTypes = {
  data: PropTypes.shape({
    product_list: PropTypes.object.isRequired,
  }).isRequired,
};

export default ProductCollection;
