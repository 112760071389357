import React, { createContext, useContext, useState, useCallback, useEffect } from "react";

const EventContext = createContext();

export const EventProvider = ({ children }) => {
    const [event, setEventState] = useState(null);

    const setEvent = useCallback((newEvent) => {
        setEventState(newEvent);
    }, []);

    useEffect(() => {
    }, [event]);

    return (
        <EventContext.Provider value={{
            setEvent,
            event,
        }}>
            {children}
        </EventContext.Provider>
    )
}

export const useEvent = () => {
    const context = useContext(EventContext);

    if(!context) {
        throw new Error('EventContext must be used within a EventProvider')
    }


    return context;
}
