import React from 'react';
import {StandardSection} from "../../../../layout/sections/StandardSection.jsx";
import {ButtonComponent} from "../../../../components/ui/buttons/index.js";
import {useDrawer} from "../../../../js/hooks/context/DrawerContext.jsx";
import { VideoComponent } from '../../../../components/ui/VideoComponent.jsx';

const AddonProduct = ({ product }) => {

    const { openPreviousDrawer } = useDrawer();

    const files = product.files ?? [];
    const hasFiles = files && files.length > 0;
    const video = hasFiles ? files.find(file => file.category === 'videos') : null;

    console.log('video', video)

    return (
        <StandardSection>

            { video && <VideoComponent data={video} /> }
            { product.description && <div style={{color:'var(--text-colour)', display: 'flex', flexDirection: 'column', gap: '10px', textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: product.description}}></div> }
            <ButtonComponent style={{height: '40px', marginTop: '20px'}} variant={'solid'} onClick={() => openPreviousDrawer()}>Go Back</ButtonComponent>
        </StandardSection>
    );
};

export default AddonProduct;