import React from 'react';
import {heroComponentMap} from "../../../components/heroes/js/heroComponentMap.js";
import {pageSectionComponentMap} from "../../../components/ui/page-sections/js/sectionComponentMap.js";
import {Packages} from "../../../components/ui/page-sections/packages/index.js";
import {useApiQuery} from "../../../hooks/customHooks.js";
import {useParams, useSearchParams} from "react-router-dom";
import {updateTheme} from "../../themes/js/utils.js";
import {filterProductsByCategory} from "../../events/js/utils.js";
import {ProductList} from "../../ecommerce/products/components/index.js";
import {StandardSection} from "../../../layout/sections/StandardSection.jsx";
import {ImpactTitle, InstructionTitle, Paragraph, Title} from "../../../components/ui/typography/index.js";
import {FullPageFlexCenter} from "../../../layout/styles/index.js";
import {ImageComponent} from "../../../components/ui/images/ImageComponent.jsx";
import {VideoComponent} from "../../../components/ui/VideoComponent.jsx";
import {mainMenu} from "../../../layout/menus/js/mainMenu.js";
import {API_URL} from "../../../js/Helper.js";
import {PrimaryCardPage} from "../../cards/pages/index.js";
import {useDrawer} from "../../../js/hooks/context/DrawerContext.jsx";
import {PackForm} from "../components/index.js";




const Pack = () => {

    const params = useParams();
    // Initialize URLSearchParams with the query string from the current URL
    const searchParams = new URLSearchParams(window.location.search);

    // Retrieve the value of the 'ref' query parameter
    const referrer = searchParams.get('ref');


    const { openDrawer } = useDrawer();
    const slug = params.slug;
    const contactId = params.contactId;
    const Hero = heroComponentMap['minimal'];
    const ContactSection = pageSectionComponentMap['contact'];

    const inputs = [
        {name: 'Business Name', type: 'text', label: 'Business Name'},
        {name: 'Name', type: 'text', label: 'Name', placeholder: 'Jack Black'},
        {name: 'email', type: 'text', label: 'Email', placeholder: 'j.black@yourbusiness.com'},
        {name: 'number', type: 'number', label: 'Phone Number', placeholder: '+447814391390'},
        {name: 'contact_id', type: 'hidden', value: contactId},
        {name: 'questions'}

    ]


    const { data, isLoading, refetch, error, isSuccess} = useApiQuery(`packs/${slug}`, (data) => {
      console.log(data)
        updateTheme(data.data.theme)
    })

    const card = useApiQuery(`card/o-card`,
        data => {

            if(data.status === 'success') {
                // updateTheme(data.card.default_tier.theme)
            }

        },
        error => console.error(error.message)
    );

    const handlePackageClick = (id) => {
        openDrawer(<PackForm name={id} inputs={inputs} referrer={referrer}  />, id)
    }


    const pack = data?.data;
    const products = pack?.products;
    const files = pack?.files
    const coverPhoto = files?.find((file) => file.name === 'CoverPhoto')?.cdnUrl
    const mainVideo = files?.find((file) => file.name === 'MainVideo');
    const packages = filterProductsByCategory(products, 'sponsorship_package')
    const deliverables = filterProductsByCategory(products, 'deliverable')
    const contact = pack?.contactInfo?.find((contact) => contact.id === Number(contactId))

    if(isLoading) return;




    return (
        <div>
            <Hero title={pack.title} lowerTitle={pack.lowerTitle} upperTitle={pack.upperTitle} image={coverPhoto}  />
            <PackDescription
                mainTitle={pack.title}
                subHeader={pack.upperTitle}
                header={pack?.business?.name + "'s"}
                text={pack.description}
                image={coverPhoto}
                video={mainVideo}
            />

            { packages?.length > 0 && <Packages packages={packages} onClick={handlePackageClick}  /> }
            { deliverables?.length > 0 && <ProductList data={deliverables} /> }
            {/*{ card.isSuccess && <PrimaryCardPage card={card?.data?.card} activeTier={card?.data?.card?.default_tier} /> }*/}
            { contact && <ContactSection prefix={"Contact"} name={contact.name}  email={contact.email} phone={contact.number} /> }
        </div>
    );
};

export default Pack;

const PackDescription = ({header, subHeader, mainTitle, text, image, video}) => {
    return (
        <StandardSection style={{height: 'fit-content', display: 'flex', flexDirection:'column', gap: '30px'}}>
            <ImpactTitle header={header} subHeader={''} main={mainTitle} />
            {video && <VideoComponent data={video} /> }

            <div dangerouslySetInnerHTML={{ __html: text }}></div>

        </StandardSection>
    )
}