import React from 'react';
import {CoverPage, GenericPage} from "./pages/index.js";
import Contents from "./pages/Contents.jsx";
import {InfoSection, ShortTitle} from "../../components/ui/typography/index.js";
import styled from "styled-components";
import HowWeDoIt from "./pages/HowWeDoIt.jsx";
import WhyWeExist from "./pages/WhyWeExist.jsx";
import RevenueStreams from "./pages/RevenueStreams.jsx";

const Container = styled.section`
    display: grid;
    height: 100vh;
    width: 100vw;
    //place-items: center;
    padding: 20px;
`

const Proposals = () => {
    return (
        <Container>
            {/*<CoverPage title={'Nctrl'} />*/}
            {/*<Contents/>*/}
            {/*<ShortTitle title={'WHY'} subtitle={'we exist'} />*/}
            {/*<InfoSection subtitle={'Event Management Arsenal'} paragraph={'Weapons-grade tools for ticketing, event promotion and communication.\n'} />*/}
            <GenericPage  />
            <WhyWeExist  />
            <HowWeDoIt />
            <RevenueStreams />
        </Container>
    );
};

export default Proposals;