import React from 'react';
import PropTypes from 'prop-types';
import {InfoSection, Paragraph, ShortTitle} from "../../../components/ui/typography/index.js";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100vh;
    gap: 20px;
`
const InfoSectionContainer = styled.div``

const HowWeDoIt = ({  }) => {

    const style = {
        container: {
            marginTop: '-80px',
            // marginBottom: '100px'
        }
    }

    return (
        <Container>
            <ShortTitle title={'Why'} subtitle={'We Exist'} style={style} />

            <InfoSectionContainer>
                <Paragraph style={{marginBottom: '20px'}}>Nocturnal Lifestyle isn't just building a platform; we're architecting the future of entertainment</Paragraph>
                <InfoSection subtitle={'Event Management Arsenal'} paragraph={'Weapons-grade tools for ticketing, event promotion and communication.\n'} />
                <InfoSection subtitle={'Loyalty Programs on Steroids'} paragraph={'Rewards that turn customers into fervent brand evangelists'} />
                {/*<InfoSection subtitle={'Loyalty Programs on Steroids'} paragraph={'Rewards that turn customers into fervent brand evangelists'} />*/}

            </InfoSectionContainer>

        </Container>
    );
};

HowWeDoIt.propTypes = {
    // components: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default HowWeDoIt;