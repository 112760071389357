import React from "react";
import styled from "styled-components";

const IntroContainer = styled.div`
  height: 100vh;
  background: var(--primary-colour);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 20px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${props => props.backgroundImage ? `url(${props.backgroundImage})` : 'none'};
    background-size: cover;
    background-position: center;
    filter: blur(5px) brightness(0.5);
    z-index: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, var(--primary-colour), rgba(0, 0, 0, 0));
    z-index: 1;
  }
`;

const Content = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;
`;

const LogoWrapper = styled.div`
  margin-top: 20px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
`;

const BottomContent = styled.div`
  margin-bottom: 20px;
`;

const InviteText = styled.p`
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-bottom: 1rem;
`;

const MainTitle = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 1rem;
`;

const FeaturingText = styled.p`
  font-size: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 0.5rem;
`;

const ArtistName = styled.p`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

const AssociationText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0.5;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const BrandName = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
`;

const CameraIcon = styled.span`
  font-size: 1.5rem;
`;

export const IntroSection = ({title, subtitle, guestRole, guest, backgroundImage, logo}) => {

    console.log('backgroundImage', backgroundImage)

    return (
        <IntroContainer backgroundImage={backgroundImage}>
            <Content>
                <LogoWrapper>
                    <img src={logo} alt={title} width={100} height={100}/>
                </LogoWrapper>

                <MainContent>
                    <InviteText>{subtitle}</InviteText>
                    <MainTitle>
                        {title}
                    </MainTitle>
                    <FeaturingText>{guestRole}</FeaturingText>
                    <ArtistName>{guest}</ArtistName>
                </MainContent>

                <BottomContent>
                    <AssociationText>
                        <span>In association with</span>
                        <BrandName>0 Life</BrandName>
                    </AssociationText>
                </BottomContent>
            </Content>
        </IntroContainer>
    );
};
