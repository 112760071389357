import Basket from "../index.js";
import React from "react";
import {useDrawer} from "../../../../js/hooks/context/DrawerContext.jsx";
import {useEvent} from "../../../events/js/hooks/index.js";

export const useBasket = () => {

    const { openDrawer, closeDrawer } = useDrawer();
    const { event } = useEvent();
    const openBasket = () => openDrawer(<Basket event={event} />, 'Basket', innerWidth > 768 ? 'right' : 'bottom')
    const close = () => closeDrawer();


    return {
        open: openBasket,
        close
    }
}