import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { AiFillPlayCircle } from 'react-icons/ai';
import {useEvent} from "../../features/events/js/hooks/index.js";

const ComponentContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // border: solid 1px rgba(255,255,255,0.1);
    border-radius: var(--border-radius-default);
    padding: 20px;
    
    @media screen and (min-width: 1024px) {  
        max-width: 300px;
        align-self: center;
    }
    
`
    
const PlayerContainer = styled.div`
    display: ${props => props.isPlaying  == true ? 'block' : 'none'};
    padding: 0px 20px;

    @media screen and (min-width: 1024px){
        max-width: 300px;
    }
`

const Thumbanail = styled.img`
    width: 200px;
    max-width: fit-content;
    animation: fadeIn 250ms ease-in-out;
    border-radius: var(--border-radius-default);

    @media screen and (min-width: 1024px){
        max-width: 300px;
    }

`

const ThumbnailContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-width: calc(100vw - 50px);
    width: 100%;
    max-width: fit-content;
    height: 200px;
    border: solid 1px var(--accent-colour);
    border-radius: var(--border-radius-default);
    background-color: var(--primary-colour);

    svg {
        position: absolute; /* Ensure the icon is absolutely positioned within the container */
        font-size: 70px;
        z-index: 10; /* Higher z-index to ensure it's on top of the thumbnail */
        color: white;

       &:active {
        color: var(--accent-colour);
        font-size: 30px; /* You might want to adjust the active state size to ensure it doesn't get too small */
       }
    }

    @media screen and (min-width: 768px) {
        min-width: 250px;
    }

    @media screen and (min-width: 1024px) {
        min-width: 375px;
    }
`;

 
export const VideoComponent = ({data, numberOfItems, style, url, thumbnail}) => {
    const [playing, setPlaying] = useState(false);
    // Remove unused state: const [displayPlayer, setDisplayPlayers] = useState();
    // const { event } = useEvent(); // Unused, consider removing if not needed

    console.log('video', data) // Consider removing or using for debugging only

    const standByStyle = {
        minWidth: numberOfItems > 1 ? innerWidth > 1000 ? 'fit-content' : '100%' : 'fit-content',
        maxHeight: '80dvh',
        objectFit: 'contain'
    }

    const playingStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.75)',
        backdropFilter: 'blur(10px)',
        minWidth: '100dvw',
        minHeight: '100dvh',
        maxHeight: '100dvh',
        position: 'fixed',
        left: '0',
        top: '0',
        animate: 'fadeIn 250ms ease-in-out',
        zIndex: 1000
    }

    const handlePlay = () => {
        setPlaying(true);
    };

    const handlePause = () => {
        setPlaying(false);
    };

    return (
        <ComponentContainer style={style}>  
            <ReactPlayer 
                url={url ?? data?.cdnUrl}
                width={1920}
                height={1080 / 4}
                controls
                onPlay={handlePlay}
                onPause={handlePause}
                playing={playing}
                pip={false}
                stopOnUnmount={true}
                style={{
                    display: 'block', // Always display the player
                    overflow: 'hidden',
                    paddingBottom: '-10px',
                    // border: 'solid 1px rgba(255,255,255,1)'
                }}
            />

             {!playing && thumbnail || data?.thumbnail && (
                <ThumbnailContainer onClick={handlePlay}>
                    <Thumbanail 
                        src={thumbnail ?? data?.thumbnail}
                        alt="Video Thumbnail"
                        style={{
                            position: 'relative',
                            minWidth: '100%',
                            zIndex: 5, 
                            top: '0', 
                            left: '0', 
                            maxHeight:'1920px', 
                            width: '100%', 
                            height: '100%', 
                            objectFit: 'cover', 
                            cursor: 'pointer'
                        }}
                    />                
                    <AiFillPlayCircle/>                
                </ThumbnailContainer> 
            )}
        </ComponentContainer>
    );
}
