import axios from "axios";
import { API_URL } from "../js/Helper";
import { toast } from "react-toastify";

const makeRequest = async (method, url, data, headers, contentType = null) => {
  try {
    // if the url does begins with a forward slash, do nothing
    // if it does not have a forward slash then prepend the CMS_API_URL
    let endpoint = url[0] == '/' ? `${url}` : `${API_URL}/${url}`;

    // if the url does not have a forward slash
    // and the url does not include http
    // then add the forward slash
    if (!url[0] === ('/') && !url.includes('http')) endpoint = '/' + endpoint;

    // if the url includes http then we keep it as it is
    if (url.includes('http')) endpoint = url;

    // Fetch the CSRF token from the meta tag
    // let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const response = await axios({
      method: method,
      url:  endpoint,
      data: data,
      headers: {
        AccessControlAllowOrigin: '*',
        'Content-Type': contentType != null ? contentType : 'application/json',
        // 'X-CSRF-TOKEN': token,  // Add the CSRF token to the headers
        'ngrok-skip-browser-warning': true,
        'Accept': 'application/json',
        ...headers,
      },
    });

    // Check if the response status is not successful
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.data.message || 'Something went wrong');
    }

    // if(response.status >= 200 && response.status < 300) {
    //   response.data.message && toast.success(response.data.message);
    // }

    return response.data;
  } catch (error) {
    console.error('Error making request', error);
    throw error;
  }
};

export { makeRequest };
