import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { BUILD_VERSION, scrollToElement } from '../../../js/Helper'
import { ImCross } from 'react-icons/im'
import { iconsMap } from '../../../js/maps/IconMap';
import { AuthContext } from '../../../features/authentication';
import { useDrawer } from '../../../js/hooks/context/DrawerContext'
import { SlideOutMenu } from '../menus'
import Basket from "../../../features/ecommerce/basket/index.js";


const FloatingNavContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  min-width: 100dvw;
  width: 100dvw;
  max-width: 500px;
  height: 100px;
  z-index: 999;
  padding: 0px 20px;
  box-sizing: border-box;

  @media screen and (min-width: 768px) {
    left: 0;
  }

  @media screen and (min-width: 1024px) {

  }
`

const FloatingNavBar = styled.div`
  display: flex;
  gap: 10px; 
  border-radius: var(--border-radius-default);
  min-width: var(--small);
  width: var(--small);
  max-width: 400px;
  height: 65px;
  
  padding: 10px 20px;
  box-sizing: border-box;
  
  //box-shadow:  5px 10px 10px rgba(0,0,0,0.2), -5px -10px 10px rgba(0,0,0,0.1);
  box-shadow: inset 10px 10px 25px rgba(0,0,0,0.5);
  
  animation: fadeSlideUp 1s;
  background-color: ${props => props.appearance == 'default' ? 'var(--default-primary-colour-fade)' : 'var(--accent-colour)'};
  background: ${props => props.appearance == 'default' ? 'var(--default-primary-colour-fade)' : 'var(--menu-bg-colour)' || 'var(--accent-colour)'};
  border: ${props => props.appearance == 'default' ? 'solid 1px var(--default-accent-colour)' : ''};
  backdrop-filter: ${props => props.appearance == 'default' ? 'blur(8px)' : ''};
  
  @media screen and (min-width: 768px) {
    min-height: 65px;
    height: 40px;
    width: 400px;
  }

  a {
    display: inherit;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    font-size: 20px;
    color: white;

    p {
      font-size: 0.5em;
      margin: 0;
      width: 50px;
      text-align: center;
      line-height: normal;
    }
  }

  @media screen and (min-width: 1024px) {
    min-width: 400px;
    width: 400px;
    max-width: 400px;
  }

`

const BackArrow = styled.div`
  width: 30px;
  margin-right: 10px;
  font-size: 20px;
`

const MenuItemContainer = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -10px;
  min-width:20px;
  width: 30px;
  max-width: 60px;
  min-height: 100%;
`

const MenuItemName = styled.span`
  display: flex;
  font-size: 0.5em;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  line-height: normal;
  font-weight: bold;
  margin-top: -5px;
  color: var(--text-colour);
  opacity: 0.5;
`

const MenuItemIcon = styled.span`
  font-size:1.1em;
  line-height: normal;
  padding: 0px;
  color: var(--text-colour);
`


const FullScreenContainer = styled.div`
  display: ${props => props.show === true ? 'flex' : 'none'};
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100dvw;
  height: 100dvh;
  padding: 20px 0px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  animation: fadeIn 350ms ease-in-out;
  backdrop-filter: blur(8px);
  `

const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
  width: 100vw;
  height: 100vh;
  opacity: 0.95;
  background-color: var(--primary-colour-half);
  box-shadow: var(--default-shadow);
  padding: 50px  0px;
  box-sizing: border-box;


  #mobile-menu-top {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ::before {
      content: '';
      position: absolute;
      right: 50; /* Position it at the left edge of the div */
      bottom: 0; /* Position it at the top edge of the div */
      width: 100px; /* This will be the "length" of the border */
      height: 1px; /* This will be the "width" of the border */
      background-color:white;
    }
  }

  #middle-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #menu-bottom {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0px;

    ::before {
      content: '';
      position: absolute;
      left: 50; /* Position it at the left edge of the div */
      top: 0; /* Position it at the top edge of the div */
      width: 100px; /* This will be the "length" of the border */
      height: 1px; /* This will be the "width" of the border */
      background-color:white;
    }
  }
`;

const SectionContainer = styled.section``

const Logo = styled.img``

const FloatingNavMenuItem = ({ name, icon: Icon }) => {
  return (
    <MenuItemContainer >
      <MenuItemIcon>{Icon && <Icon />}</MenuItemIcon>
      <MenuItemName>{name}</MenuItemName>
    </MenuItemContainer>
  )
}

export const FloatingNav = ({items, theme, showBack }) => {

  const [membershipTheme, setMemTheme] = useState();
  const [menuItems, setMenuItems] = useState([]);
  const [sortedMenuItems, setSorted] = useState([]);

  const [fullMenu, setFullMenu] = useState(false);

  const { isOpen, openDrawer } = useDrawer();
  const { logout } = useContext(AuthContext)
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();


  /**
  //  * TODO: CREATE RELATION BETWEEN PAGES AND MENU ITEMS 
   * TODO: IMPORT THE MENU AS A COMPONENT IN THE PORTAL 
   * TODO: LOAD SPECIFIC MENU ITEMS FOR EACH PAGE
   * 
   * */ 
  
  useEffect(() => {
    let sortedMenuItems = items?.sort((a, b) => {
      return a.order - b.order;
    });

    setSorted(sortedMenuItems)
  },[menuItems, items])

  const goBack = () => {
    navigate(-1)
  }

  return (
    <>

      <FullScreenContainer id='full-screen-mobile-menu' show={fullMenu}>
        <ContentContainer id='mobile-menu-container'>
          
          <SectionContainer id='mobile-menu-top'>
            <Logo width={'100px'} src='/nocturnal-logo-clear.png'/>
            <br></br>
          </SectionContainer>

          <SectionContainer id='middle-menu'>
            {
              sortedMenuItems?.length > 0 && sortedMenuItems.map((item, index) => {
                const Icon = iconsMap[item.icon] || iconsMap['IoCardOutline']

                return (
                  <Link  key={index}>{item.name}</Link>
                  )
                })
                
              }

              <Link to={'/'}>{'Back To App'}</Link>
          </SectionContainer>
          
          <SectionContainer id='menu-bottom'>
            <br></br>
            <Link onClick={() => logout()} >Log Out</Link>
            <br></br>
            <Link>Terms & Conditions</Link>
            <Link>Privacy Policy</Link>
            <span style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center', marginTop:'10px'}}>
            <p style={{margin: 0}}>Nocturnal Lifestyle</p>
            <p style={{margin: 0}}>Version {BUILD_VERSION}</p>
          </span>
            <br></br>
            <ImCross onClick={() => setFullMenu(fullMenu == true ? false : true)}/>
          </SectionContainer>

        </ContentContainer>
      </FullScreenContainer>
          

      {
      sortedMenuItems?.length > 0 && (
        <FloatingNavContainer id='floating-nav'>
    
          <FloatingNavBar appearance={theme} theme={membershipTheme}>
            {
              sortedMenuItems.map((item, index) => {
                const Icon = iconsMap[item.icon] || iconsMap['IoCardOutline'];
                return (       
                  
                  /**
                   * TODO: CHANGE THIS ITEM.DESTINATION {showMenu} STUFF
                   */
                <Link
                onClick={() => (item.destination === 'menu' ? openDrawer(<SlideOutMenu />, null, 'right') : '')}
                key={index}
                to={item.destination !== 'menu' ? item.destination : ''}
                 >
                  <FloatingNavMenuItem name={item.name} icon={Icon} />
                </Link>
                )
              })
            }
          </FloatingNavBar>
        </FloatingNavContainer>
      )}
    </>
  );
};
