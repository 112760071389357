import { RectangleCardComponent } from "./RectangleCardComponent/RectangleCardComponent"
import { FeaturedCardComponent } from "./FeaturedCardComponent"
import { SquareCardComponent } from "./SquareCardComponent"
import { LoyaltyCard } from "../../../features/cards/components/LoyaltyCard/LoyaltyCard"
import { VideoComponent } from "../VideoComponent"
import MiniLoyaltyCard from "../../../features/cards/components/LoyaltyCard/MiniLoyaltyCard.jsx";
import {ImageComponent} from "../images/ImageComponent.jsx";

export const cardComponentMap = {
    RectangleCardComponent,
    FeaturedCardComponent,
    SquareCardComponent,
    LoyaltyCard,
    VideoComponent,
    MiniLoyaltyCard,
    ImageComponent
}