import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import {useDrawer} from "../../../js/hooks/context/DrawerContext.jsx";
import {ButtonComponent} from "../../../components/ui/buttons/index.js";
import {Divider} from "../../../components/ui/Divider.jsx";
import {useApiQuery} from "../../../hooks/customHooks.js";
import {API_URL} from "../../../js/Helper.js";
import {updateTheme} from "../../themes/js/utils.js";
import {Loading} from "../../../components/ui/Loading.jsx";
import {useParams} from "react-router-dom";
import {useCard} from "../js/index.js";
import {StandardSection} from "../../../layout/sections/StandardSection.jsx";

// Styled Components (as before)

// Wrapper for two-column layout on larger screens
const MembershipCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 40px;
  }
`;
const CardContainer = styled.div`
    //border-radius: var(--border-radius-default);
    width: 100vw;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    display: flex;
    padding: 20px;
    flex-direction: column;
    color: var(--text-colour);
    align-items: center;
    background: var(--primary-colour);
    overflow: hidden; // Ensure child components don't overflow
    z-index: 3;
`;

const CardBase = styled.div`
    width: 100%;
    max-height: 200px;
    max-width: 350px;
    // background-image: ${(props) => props.image};
    background: var(--primary-colour);
    background-image: ${props => `url(${props.image})`};
    
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--border-radius-default);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    //border: 1px solid rgba(255, 255, 255, 0.09);
    margin: 0 10px;
    z-index: 4;
    transition: opacity 0.3s ease;

    @media (min-width: 1024px) {
        //background-image: none; /* Remove background image */
    }
`;


// Adjusted width and height for smaller tier cards
export const TierCard = styled(CardBase)`
    min-width: 266px; /* 33% less than the main MemberCard's width (350px * 0.67) */
    height: 160px; // Ensure height remains consistent with previous adjustments
    max-width: 230px;
    opacity: ${(props) => (props.isSelected ? 1 : 0.5)}; // Adjust opacity based on selection
    scroll-snap-align: center; // Snap to center

    @media  (min-width: 1024px) {
        height: 300px;
    }
`;

export const CardText = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
`;

export const CardHeader = styled.div`
    width: 100%;
    height: 180px; // Adjust height to desired size for the header
    background-size: cover;
    background-position: center;
    position: relative; // Allow for layering of gradient
    color: var(--text-colour);

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //background: linear-gradient(to bottom, transparent, var(--default-primary-colour));
        pointer-events: none; // Allow interaction with elements beneath
    }
`;

const CardHeaderText = styled.div`
    color: white;
    font-weight: bold;
`;

export const CardFooter = styled(CardText)`
    font-size: 14px;
    align-items: flex-end;
    flex-direction: column;
    line-height: 1.2; /* Shortened line height */
`;

const Title = styled.h1`
    font-size: 24px;
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
    color: var(--accent-colour);
`;

const Paragraph = styled.p`
    font-size: 14px;
    //text-align: center;
    margin-bottom: 20px;
    line-height: 1.5;
    color: var(--text-colour);
    //color: black;
`;

const Section = styled.div`
    margin: 20px 0;
    width: 100%;
    background: var(--accent-colour);
`;

const SectionTitle = styled.h3`
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
    color: var(--accent-colour);
`;

export const ScrollContainer = styled.div`
    display: flex;
    overflow-x: auto;
    overflow-y: hidden; // Ensure no vertical scroll
    padding: 10px 10px;
    margin: 0 -20px; /* Negative margin to pull scrollbars off-screen */
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    scroll-snap-type: x mandatory; // Enable snappy scrolling
    scroll-behavior: smooth; // Smooth scrolling

    &::-webkit-scrollbar {
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: var(--border-radius-default);
    }
`;

// Container for the benefits section
const BenefitsSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    gap: 10px;
`;

// BenefitsList component for the container
const BenefitsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px; // Space between each benefit item
    max-width: 100%; // Ensure it takes up full width
`;

// BenefitItem component for each item
const BenefitItem = styled.div`
    flex: 1 1 105px; // Allows item to grow, shrink and have a base width of 140px
    background-color: #c2c2c2; // Light gray background as shown in the image
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 5px 30px;
    border-radius: var(--border-radius-default);
    font-size: 0.7rem;
    color: #1e1f29; // Dark text color
    width: fit-content;
    //min-width: 50px; // Minimum width to maintain readability
    max-height: 50px;
    height: fit-content;
    min-height: 43px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
    text-transform: uppercase; // Uppercase text as shown in the image
    font-weight: bold;
    transition: all 0.3s ease; // Smooth transition for resizing
`;

const LocationsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

const Location = styled(BenefitItem)`
    background-color: #353546;
`;

const Gallery = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
`;

const GalleryItem = styled.div`
    width: 60%; // Set to 60% of the container's width
    min-width: 70%;
    height: 413px; // Maintain the Instagram Story aspect ratio
    background-size: cover;
    background-position: center;
    border-radius: var(--border-radius-default);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 14px; // Increase font size for visibility
    padding-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Subtle shadow for depth
    position: relative;
    overflow: hidden;
    margin: 0 10px; // Add margin between gallery items
    scroll-snap-align: center; // Snap to center

    // Overlay for darkening the image and highlighting the text
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0.3),
                rgba(0, 0, 0, 0.7)
        ); // Gradient for overlay
        z-index: 1;
    }

    // Name text at the bottom
    & > div {
        z-index: 2; // Ensure text is above the overlay
        margin-bottom: 10px; // Space from bottom
        text-transform: uppercase; // Uppercase for the name
        font-size: 16px; // Slightly larger font size
        line-height: 1.2; // Adjust line height
    }
`;

const GalleryScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden; // Ensure no vertical scroll
  padding: 10px;
  margin: 0 -20px; // Negative margin to pull scrollbars off-screen
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  scroll-snap-type: x mandatory; // Enable snappy scrolling
  scroll-behavior: smooth; // Smooth scrolling

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: var(--border-radius-default);
  }
`;


// New ButtonContainer with gradient overlay
const ButtonContainer = styled.div`
    width: 100%;
    padding: 20px; // Add padding for spacing
    margin-top: 40px; // Add top margin for separation
    background: linear-gradient(to top, var(--primary-colour), transparent); // Gradient overlay
    display: flex;
    justify-content: center; // Center the button horizontally
`;

const MemberCardContainer = styled.div`
    display: grid;
    place-items: center;
    padding-top: 20px;
    //padding: 40px 0px;
    position: relative; // Relative positioning for absolute children
    width: 100vw;
    margin-top: -20px;
    z-index: 1;
    // background-image: ${props => `url(${props.image})`};
    background-size: contain;
    background-position: center;
    overflow: hidden; // To hide overflow of child elements

    // Pseudo-element for gradient overlay
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //background: linear-gradient(to top, var(--primary-colour), transparent);
        z-index: 0; // Ensure the overlay is above the image
        pointer-events: none; // Allows interaction with underlying elements
    }
`;

export  const TierSection = styled(Section)`
    width: 100vw;
    //background: var(--accent-colour);
    padding: 20px 20px 20px 25px;
`

const SelectedTierSection = styled(Section)`
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--primary-colour); /* Dark background color for the section */
    border-radius: var(--border-radius-default);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
    border: 1px solid rgba(255, 255, 255, 0.05); /* Faint light border */
    margin: 20px 0; /* Vertical margin to separate sections */
    padding: 10px 20px;
    width: 100%; /* Full width for responsive design */
    max-width: 800px; /* Max width to constrain the size on large screens */
    
`


// MembersCard Component
export const MembersCardComponent = ({ cardName, cardType, tierName, userName, points, image }) => (
    <MemberCardContainer
        id={'member-card'}
        image={image}
    >
        <CardBase image={image}>
            <CardHeader>
                <div>{cardName}</div>
                <div>{tierName}</div>
            </CardHeader>
            <CardFooter>
                <div>{userName}</div>
                <div><strong>{points} Points</strong></div>
            </CardFooter>
        </CardBase>
    </MemberCardContainer>
);

// Main React Component
const MembershipCard = () => {
    // Dummy data
    // const tiers = [
    //     {
    //         id: 1,
    //         cardName: "Nocturnal",
    //         tierName: "Standard Tier",
    //         userName: "Hezzron Austin",
    //         points: "500 Points",
    //         description:
    //             "The Standard Tier offers basic benefits and access to exclusive events.",
    //         benefits: [
    //             {
    //                 text: "Members Only Discounts",
    //                 description:
    //                     "Access exclusive discounts available only to members of the club.",
    //             },
    //             {
    //                 text: "Customer Referral Scheme",
    //                 description:
    //                     "Earn rewards for referring new customers to join our membership program.",
    //             },
    //         ],
    //         locations: ["Austin's Kitchen", "The Farda's Grill"],
    //         gallery: [
    //             "https://via.placeholder.com/150",
    //             "https://via.placeholder.com/150",
    //             "https://via.placeholder.com/150",
    //         ],
    //         imageUrl:
    //             "https://pub-a305029e2a79415898ef080b84d16b39.r2.dev/images/0lifecard.png", // Keep the current image for this tier
    //     },
    //     {
    //         id: 2,
    //         cardName: "Nocturnal",
    //         tierName: "Premium Tier",
    //         userName: "Hezzron Austin",
    //         points: "1000 Points",
    //         description:
    //             "The Premium Tier offers additional benefits, priority access, and partner offers.",
    //         benefits: [
    //             {
    //                 text: "Exclusive Events",
    //                 description:
    //                     "Get invited to private events and special gatherings hosted by us.",
    //             },
    //             {
    //                 text: "Priority Access",
    //                 description:
    //                     "Enjoy priority access to new services and promotions before others.",
    //             },
    //         ],
    //         locations: ["Hoodworths", "The Farda's Grill"],
    //         gallery: ["https://picsum.photos/150", "https://picsum.photos/150"],
    //         imageUrl: "https://picsum.photos/300/160", // Random image for Premium Tier
    //     },
    //     {
    //         id: 3,
    //         cardName: "Nocturnal",
    //         tierName: "Elite Tier",
    //         userName: "Hezzron Austin",
    //         points: "1500 Points",
    //         description:
    //             "The Elite Tier offers all benefits, including partner offers and exclusive access.",
    //         benefits: [
    //             {
    //                 text: "Partner Offers",
    //                 description:
    //                     "Benefit from exclusive offers from our partners in various sectors.",
    //             },
    //             {
    //                 text: "VIP Access",
    //                 description: "Experience VIP access to exclusive events and services.",
    //             },
    //         ],
    //         locations: ["Austin's Kitchen", "Hoodworths"],
    //         gallery: [
    //             "https://placeimg.com/150/150/any",
    //             "https://placeimg.com/150/150/any",
    //         ],
    //         imageUrl: "https://placeimg.com/300/160/any", // Random image for Elite Tier
    //     },
    // ];

    // 1. Define the default tier as the first tier in the array
    const [tiers, setTiers] = useState([]);
    const default_tier = tiers[0] ?? [];
    const params = useParams();
    const { card, setCard } = useCard();
    const slug = params.slug;
    // 2. Use state to track the selected tier
    const [selectedTier, setSelectedTier] = useState(default_tier);


    // 3. Create a ref for the scroll container
    const scrollRef = useRef(null);

    // 4. Get the openDrawer function from useDrawer hook
    const { openDrawer } = useDrawer();


    // 6. Effect to add scroll event listener
    useEffect(() => {
        const scrollContainer = scrollRef.current;

        // Function to handle scroll event
        const handleScroll = () => {
            // Calculate the center of the scroll container
            const centerX = scrollContainer.scrollLeft + scrollContainer.offsetWidth / 2;

            // Find the closest tier to the center
            const closestTier = Array.from(scrollContainer.children).reduce(
                (closest, tier, index) => {
                    const rect = tier.getBoundingClientRect();
                    const tierCenterX = rect.left + rect.width / 2;
                    const offset = Math.abs(centerX - tierCenterX);

                    if (offset < closest.offset) {
                        return { index, offset };
                    }
                    return closest;
                },
                { index: -1, offset: Infinity }
            );

            // Set the selected tier based on the closest tier
            setSelectedTier(tiers[closestTier.index]);
        };

        // Add scroll event listener
        scrollContainer?.addEventListener("scroll", handleScroll);

        // Clean up the event listener on component unmount
        return () => scrollContainer?.removeEventListener("scroll", handleScroll);
    }, [tiers]);


    const { data, isLoading, isError, refetch } = useApiQuery(`card/${slug}`,
        data => {

            if(data.status === 'success') {
                updateTheme(data?.card?.default_tier?.theme)
                setCard(data.card)
                // updateTheme(data.card.default_tier.theme)
                setSelectedTier(data.card.default_tier)
                updateTheme(data.card.default_tier.theme)
                setTiers(data?.card?.tiers)
                console.log('success', data)
            }

        },
        error => console.error(error.message)
    );

    if (isLoading) {
        return <Loading/>;
    }

    if (isError) {
        return <h1>Error</h1>
    }

    if(!data) {
        return <Loading/>;
    }


    // 5. Function to open the drawer with benefit details
    const handleBenefitClick = (benefit) => {
        openDrawer(
            <Paragraph style={{padding: '20px'}}>{benefit.description}</Paragraph>, benefit.text);
    };



    // Updated onClick handler to scroll the selected tier into view
    const handleTierClick = (tier, index) => {
        setSelectedTier(tier);

        // Scroll the clicked tier into view
        const scrollContainer = scrollRef.current;
        const tierElement = scrollContainer.children[index];

        tierElement.scrollIntoView({
            behavior: 'smooth',  // Enable smooth scrolling
            block: 'nearest',    // Align the nearest side of the tier to the scroll container's nearest edge
            inline: 'center',    // Center the tier horizontally in the view
        });
    };

    return (
        <CardContainer>
            {/* Main Membership Card displaying the selected tier */}
            <MembersCardComponent
                cardName={selectedTier?.card?.name}
                tierName={selectedTier?.name}
                userName={selectedTier?.userName}
                points={selectedTier?.initial_value}
                image={selectedTier?.image?.cdnUrl}
                cardType={card.type}
            />

            <SelectedTierSection>
                <Title>O Life Membership</Title>
                <Divider style={{margin: '10px 0px', minWidth: '60%'}} />
                {/*<div dangerouslySetInnerHTML={selectedTier.name}></div>*/}
            </SelectedTierSection>


            <TierSection className={'background-pattern'}>
                <SectionTitle>Tiers</SectionTitle>
                <ScrollContainer ref={scrollRef} >
                    {tiers.map((tier, index) => (
                        <TierCard
                            key={index}
                            isSelected={selectedTier.id === tier.id} // Determine if this tier is the selected one
                            onClick={() => handleTierClick(tier, index)} // Updated to include index for scrolling
                        >
                            <CardHeader>
                                <div>{tier?.card?.name}</div>
                                <div>{tier.name}</div>
                            </CardHeader>
                            <CardFooter>
                                <div>{tier.userName}</div>
                                <div>{tier.points}</div>
                            </CardFooter>
                        </TierCard>
                    ))}
                </ScrollContainer>
            </TierSection>

            <SelectedTierSection>
                <Section style={{background: 'none'}}>
                    <SectionTitle>{selectedTier.name}</SectionTitle>
                    <Paragraph>{selectedTier.description}</Paragraph>
                </Section>

                <Section style={{background: 'none'}}>
                <SectionTitle>Benefits</SectionTitle>
                    <BenefitsSectionContainer>
                        <BenefitsList>
                            {selectedTier?.benefits?.map((benefit, index) => (
                                <BenefitItem
                                    key={index}
                                    onClick={() => handleBenefitClick(benefit)}
                                >
                                    {benefit.text}
                                </BenefitItem>
                            ))}
                        </BenefitsList>
                    </BenefitsSectionContainer>
                </Section>

                <Section>
                    <SectionTitle>Can Be Used At</SectionTitle>
                    <BenefitsSectionContainer>
                        <BenefitsList>
                            {selectedTier?.locations?.map((location, index) => (
                                <BenefitItem key={index}>{location}</BenefitItem>
                            ))}
                        </BenefitsList>
                    </BenefitsSectionContainer>
                </Section>
            </SelectedTierSection>


            <Section>
                <SectionTitle>Gallery</SectionTitle>
                {/* Use the existing ScrollContainer for the gallery */}
                <ScrollContainer>
                    {selectedTier?.gallery?.map((item, index) => (
                        <GalleryItem
                            key={index}
                            style={{ backgroundImage: `url(${item?.image})` }}
                        >
                            <div>{item?.name}</div>
                        </GalleryItem>
                    ))}
                </ScrollContainer>
            </Section>


            <ButtonContainer>
                <ButtonComponent variant={'solid'}>Sign Up Now</ButtonComponent>
            </ButtonContainer>
        </CardContainer>
    );
};

export default MembershipCard;
