import React, {useState} from 'react'
import { APP_URL, CDN_URL } from '../../js/Helper'
import { Link } from 'react-router-dom'
import { useMultiTenant } from '../../features/multitenant/hooks/useMultiTenant';
import { getCSSVariableValue } from '../../features/themes/js/utils';

const LogoComponent = ({ width, style, customVariant }) => {

  const { isSubdomain, getSubdomain } = useMultiTenant();
  const [showLogo, setShowLogo] = useState(1);

  const variant = customVariant ? getCSSVariableValue('--theme') === 'dark' ? 'light' : 'dark' : customVariant;
  const mediaServerUrl = CDN_URL;
  const logosDirectory = '/assets';
  const path = mediaServerUrl + logosDirectory;

  const nocturnalLogo = `${path}/nocturnal-logo${variant ? `-${variant}` : ''}.png`

  let logo;

  if(isSubdomain) {
    logo = `${path}/${getSubdomain}-logo${variant ? `-${variant}` : ''}.png`;
  } else {
    logo = nocturnalLogo;
  }

  const logos = {
    1: `${CDN_URL}/assets/nocturnal-logo-light.png`,
    2: 'https://pub-a305029e2a79415898ef080b84d16b39.r2.dev/assets/four-four-icon.png'
  }




  return (
    <Link style={style} onClick={() => setShowLogo(showLogo === 1 ? 1 : 1)}  to={null}>
      <img src={logos[showLogo]} width={width || showLogo === 1 ? '150px' : '100px'} alt={'Logo'}/>
    </Link>
  )
}

export default LogoComponent