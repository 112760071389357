import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from "@tanstack/react-query"
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components'
import { toast } from 'react-toastify';

import { AuthContext } from '../../authentication';
import { SetLocalStorageJSON, getSessionStorage, imagePath, setSessionStorageJSON } from '../../../js/Helper';
import { createMembership } from '../../cards/memberships';
import { LoyaltyCard } from '../components/LoyaltyCard/LoyaltyCard';
import { FadedBgImage } from '../../../components/ui/FadedBgImage';
import { Paragraph, Title } from '../../../components/ui/typography';
import { ImageUpload, Select } from '../../../components/forms';
import { LoginButton } from '../../../features/authentication/components/LoginButton';
import { ButtonComponent } from '../../../components/ui/buttons';
import { TextInput } from '../../../components/forms/inputs';



const TwoColumnContainer = styled.div`
display: flex;
flex-direction:p row;
justify-content: space-between;
width: 100%;
max-width: 400px;
gap: 10px;
height: 100%;


@media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

`
const CardSignup = () => {

    // useNavigate used to redirect user 
    const navigate = useNavigate();
    const location = useLocation();

    const { isAuthenticated } = useContext(AuthContext)
    const { user } = useContext(AuthContext)
    
    // Define state variables for form inputs
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [instagram, setInstagram] = useState('');
    const [image, setImage] = useState('');
    
    const card = getSessionStorage(`card`) 
    const tier = getSessionStorage(`tier`)

    if(card == null || tier == null) {
        return 'error';
    }

    const activeTheme = tier.theme;

    // define otherstate variables 
    const [response, setResponse] = useState('');
  
    // useMutation used to call and manage the api post to submit
    const createMemberMutation = useMutation({
        mutationFn: createMembership,
        onSuccess: (data) => {

            console.log(data)

            // set the data for the confirmation page
            localStorage.setItem(`card-${data.card_tier_id}-status`, data.status)

            // if the member property is returned then this user is already member 
            if(data.status != 'success'){
                setResponse(data.message)
                toast.warning(data.message)
                return;
            }

            if(data.member) {
                SetLocalStorageJSON('member', data.member)
            }

            setSessionStorageJSON('card', card)
            setSessionStorageJSON('tier', tier)

            // This data is only to be set if the status of the new member is active
            if(data.status == 'active'){
                localStorage.setItem(`member_id`, data.membder_id)
            }


            toast.success(data.message)

            sessionStorage.setItem('message', data.message);


            // show the confirmation page
            navigate(`/card/${card.slug}/${tier.id}/confirmation${location.search}`)
        },
    })

    // get the image from the image upload component 

    const getImage = (imageFromUpload) => {
        setImage(imageFromUpload)
    }

    useEffect(() => {
        if(isAuthenticated == true) {
            setFirstName(user?.first_name)
            setLastName(user?.last_name)
            setEmail(user?.email)
            setInstagram(user?.customer_contact_details?.instagram)
            setGender(user?.gender)
        }
    }, [isAuthenticated])


    const handleSubmit = (event) => {
        event.preventDefault(); // prevent page refresh on form submit

        const formData = new FormData(event.currentTarget);

        formData.append('last_name', lastName)
        formData.append('email', email)
        formData.append('gender', gender)
        formData.append('instagram', instagram)
        formData.append('card', card.id)
        formData.append('tier', tier.id)

        const data = Object.fromEntries(formData)  

        createMemberMutation.mutate(
            formData
        )
    };
    
    const submitStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        paddingBottom:'100px'
    };

    const bodyEl = document.querySelector('body')
    const cardComponent = <LoyaltyCard card={card} tier={tier} theme={activeTheme}  />

    bodyEl.style.backgroundColor = activeTheme.primary_colour
    bodyEl.style.backgroundImage =`url(${imagePath(tier?.image?.path, tier?.image?.filename)})`

    return (
        <>
        
        <div id='left' style={{backgroundColor:activeTheme.primary_colour}}>
            { innerWidth < 768 && <FadedBgImage tier={tier} />}
            { innerWidth < 768 && cardComponent}
            <Title theme={activeTheme} text={card.name} subtext={!tier?.name?.includes('default') && tier.name} />
            <section>

            <LoginButton style={{marginBottom:'-10px',marginTop:'0px'}}/>
            <form onSubmit={handleSubmit} encType="multipart/form-data">

                <TwoColumnContainer>

                <TextInput
                    name="first_name"
                    type="text"
                    placeholder="First Name"
                    autocorrect={'first_name'}
                    required={true}
                    value={firstName}
                    disabled={isAuthenticated && user?.first_name}
                    onChange={(event) => setFirstName(event.target.value)}
                    theme={activeTheme}
                />
                <TextInput
                    name="last_name"
                    type="text"
                    placeholder="Last Name"
                    autocorrect={'last_name'}
                    required={true}
                    value={lastName}
                    disabled={isAuthenticated && user?.last_name}
                    onChange={(event) => setLastName(event.target.value)}
                    theme={activeTheme}
                />
                </TwoColumnContainer>
                {/* Bind input values to state variables */}
                    <TextInput
                        name="email"
                        type="email"
                        placeholder="Email"
                        autocorrect="email"
                        required={true}
                        value={email}
                        disabled={isAuthenticated && true}
                        onChange={(event) => setEmail(event.target.value)}
                        theme={activeTheme}
                    />

                <TwoColumnContainer>

                <TextInput
                    name="instagram"
                    type="text"
                    placeholder="Instagram"
                    autocorrect="instagram"
                    required={false}
                    value={instagram}
                    onChange={(event) => setInstagram(event.target.value)}
                    theme={activeTheme}
                />
                <Select
                name="gender"
                required={true}
                onChange={(event) => setGender(event.target.value)}
                theme={activeTheme}
                />
                </TwoColumnContainer>

                {/* 
                // Store the file on the server
                // Store the file in s3
                // Save the file in the database 
                */}

                <ImageUpload 
                name="image"
                sendImageToParent={getImage}
                theme={activeTheme}
                required={true}
                />

                {
                /**
                 * TODO: Change this to an alert component
                 * */
                }

                {response && response}

                <div style={submitStyle}>
                    <ButtonComponent text="Submit Form" isLoading={createMemberMutation.isLoading} showBack={true}/>
                    <Paragraph class="small_terms" theme={activeTheme} style={{ textAlign: 'center', fontSize: '0.5em', maxWidth: '300px' }} text="By submitting this form you are agreeing to your terms and conditions. If you don't already have a Nocturnal Account one will be created for you using the email address you have provided" />
                </div>

            </form>
            </section>
            </div>

            <div id='right'>
                <LoyaltyCard card={card} tier={tier} theme={activeTheme} user={{first_name:firstName, last_name: lastName}}/>
            </div>
        </>
    )
};

export default CardSignup
