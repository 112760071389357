import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types'
import {useHandleError} from "../../../error-handling/js/hooks/index.js";
import {useEvent} from "../../../events/js/hooks/index.js";
import {orderItemSubTotal} from "../../basket/js/utils/basketUtils.js";
import axios from "axios";
import {API_URL, getSessionStorage} from "../../../../js/Helper.js";
import {generateUUID} from "../../../../js/utils/utils.js";
import {toast} from "react-toastify";
import {makeRequest} from "../../../../services/axios.js";
import {AuthContext} from "../../../authentication/index.js";

const EcommerceContext = createContext();

export const EcommerceProvider = ({ children }) => {

  const [basket, setBasket] = useState(() => {
    const savedBasket = localStorage.getItem('basket');
    return savedBasket
        ? JSON.parse(savedBasket)
        : {
          id: generateUUID(),
          sessionId: getSessionStorage('appSession')?.id,
          orderItems: [],
          mode: 'combined',
        };
  });


  const [selectedAddons, setSelectedAddons] = useState([]);


  const [paymentIntent, setPaymentIntent] = useState();
  const [config, setConfig] = useState();
  const [time,setTime] = useState()

  const { reportError } = useHandleError();
  const { event } = useEvent()
  const { header } = useContext(AuthContext)



  // Load saved basket from local storage
  useEffect(() => {
    const savedBasket = JSON.parse(localStorage.getItem('basket'));

    if(!savedBasket || savedBasket === 'undefined') {
      return;
    }

    axios.get(`${API_URL}/basket/${savedBasket?.id}/is-active`)
        .then((res) => {

          if(res.data.id === savedBasket?.id) {
            setBasket(JSON.stringify(res.data))
          }

        }).catch((err) => {
            setBasket( {
              id: generateUUID(),
              sessionId: getSessionStorage('appSession')?.id,
              orderItems: [],
              mode: 'combined',
            })
        })



    const savedConfig = sessionStorage.getItem('config');

    if (savedConfig !== 'undefined') {
      setConfig(JSON.parse(savedConfig));
    }

  }, []);

  useEffect(() => {
    sessionStorage.setItem('config', JSON.stringify(config));
  }, [config])

  // Save basket to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('basket', JSON.stringify(basket));
  }, [basket]);

  const addAddon = (addon, product) => {

    addon.quantity = product.quantity;

    const updatedBasketItems = basketItems().map(basketItem => {
      if (basketItem.id === product.id) {
        // Check if addons array exists, if not, initialize it
        const addons = basketItem.addons ? [...basketItem.addons, addon] : [addon];

        // Return the updated basket item with the new addon
        return {
          ...basketItem,
          addons: addons
        };
      }
      return basketItem;
    });

    // Update the basket with the modified product
    setOrderItems(updatedBasketItems);

    setSelectedAddons(prev => [...prev, addon]);
  };

  /**
   * Remove an addon from a product in the basket.
   * @param addon
   * @param product
   */
  const removeAddon = (addon, product) => {
    // Map through basket items to find the product and update its addons array
    const updatedBasketItems = basketItems().map(basketItem => {
      if (basketItem.id === product.id && basketItem.addons) {
        // Filter out the addon from the product's addons array
        const updatedAddons = basketItem.addons.filter(a => a.id !== addon.id);
        // Return the updated basket item without the removed addon
        return {
          ...basketItem,
          addons: updatedAddons
        };
      }
      return basketItem;
    });

    // Update the basket with the modified product
    setOrderItems(updatedBasketItems);

    // Update the selected addons state
    setSelectedAddons(prev => prev.filter(selectedAddon => selectedAddon.id !== addon.id));
  };

  const removeFromBasket = (item) => {
    setBasket(prevBasket => ({
      ...prevBasket,
      orderItems: prevBasket.orderItems.filter(orderItem => orderItem.id !== item.id)
    }));

  }

  const createOrderItem = (product) => {

    console.log('product', product)

    return {
      id: product.id,
      name: product.name,
      price: product.price,
      quantity: product.quantity,
      minQuantity: product.min_quantity,
      notices: product.basket_comment,
      numberOfGuests: product.numberOfGuests,
      fees: product.fees,
      addons: product.addons
    }
  }

const addToBasket = async (item, quantity, offer) => {

  // const product = {...item};

  const product = item;

  try {

    if(basket.orderItems.find(orderItem => orderItem.id === product.id)) return;

    /**
     * Add this if statement because kept getting errors
     * when using hide_price for some reason
     */

    if(product.fees) {
      product.fees.forEach(element => {
        element.quantity = quantity
      });
    }

    product.quantity = quantity;

    /**
     * Add the local product to the basket
     * We do it this way for quicker user feed back in case there is a delay
     * when sending the product to the server
     */

    setBasket(prevBasket => ({
      ...prevBasket,
      orderItems: [...prevBasket.orderItems, product],
    }));

    /**
     * We then add the product to the basket on the server
     * @type {Promise<void>}
     */

    const res = makeRequest('POST',
        `${API_URL}/basket/${basket.id}/add-product/${product.id}`,
        {orderItem: product,sessionId: basket.sessionId},
        header())
        .then((res) => {

          /**
           * we then update the basket with the response from the server
           * nothing should really change for the user unless there are differences
           * between the server and what is stored locally
           */

          setBasket(res?.basket)
        })
        .catch((err) => {
          console.error('error', err)

          /**
           * If there is an error we remove the product from the basket locally
           * This is only an assumption that the product was not added to the basket on the server
           * TODO - Add a check to make this more consistent.
           */

          // setBasket(prevBasket => ({
          //   ...prevBasket,
          //   orderItems: prevBasket.?orderItems?.filter(orderItem => orderItem.id !== product.id),
          // }));

        });

  } catch (e) {
    console.error('Error adding product to basket', e.message)
    reportError(e)
  }



  };


const setOrderItems = (newOrderItems) => {
  if (Array.isArray(newOrderItems)) {
    setBasket(prevBasket => ({ ...prevBasket, orderItems: newOrderItems }));
  } else {
    console.warn('Tried to set orderItems with a non-array value:', newOrderItems);
  }
};

  /**
   * Update the value of a property in an order item or its addon
   * @param productId
   * @param key
   * @param newValue
   * @returns {(*|(*&{addons: *}))[]}
   */
  const updateOrderItemValue = (productId, key, newValue) => {
    let itemFound = false;


    const updatedItems = basket.orderItems.map(item => {
      console.log(item.id, productId)
      if (item.id === productId) {
        itemFound = true;
        return {
          ...item,
          [key]: newValue
        };
      } else if (item?.addons?.length > 0) {
        // Check addons array inside each order item
        const updatedAddons = item.addons.map(addon => {
          if (addon.id === productId) {
            itemFound = true;
            return {
              ...addon,
              [key]: newValue
            };
          }
          return addon;
        });

        return {
          ...item,
          addons: updatedAddons
        };
      }
      return item;
    });


    if (!itemFound) {
      console.warn(`Item with product_id ${productId} not found.`);
    }

    setOrderItems(updatedItems);

    return updatedItems;
  }

const basketItems = () => { return basket.orderItems }

  /**
   * Get all fees from the basket items and their addons.
   * @returns {*[]}
   */
  const fees = () => {
    const items = basketItems();

    // Collect fees from main order items and their addons
    return items.flatMap(orderItem => {
      // Get fees for the main order item
      const orderItemFees = Array.isArray(orderItem.fees) ? orderItem.fees : [];

      // Get fees for the addons
      const addonFees = orderItem.addons && orderItem.addons.length > 0
          ? orderItem.addons.flatMap(addon => Array.isArray(addon.fees) ? addon.fees : [])
          : [];

      // Combine main order item fees and addon fees
      return [...orderItemFees, ...addonFees];
    });
  };

const groupedFees = () => {
  const feesGroupedByName = fees().reduce((acc, fee) => {
    // Initialize the group if it doesn't exist
    if (!acc[fee.name]) {
      acc[fee.name] = {
        ...fee,
        amount: 0 // Initialize amount to 0
      };
    }

    // Add the fee's amount to the total for this group
    acc[fee.name].amount += fee.amount * fee.quantity;

    return acc;
  }, {});

  return Object.values(feesGroupedByName);
}

  const dueToday = () => {
    let total = 0;


    basketItems()?.forEach(item => {
      // Calculate total for main product
      if (productAcceptsDeposit(item) && item.paymentOption === DEPOSIT_PAYMENT_OPTION) {
        total += item.depositAmount * item.quantity;
      } else {
        total += item.price * item.quantity;
      }

      // Calculate total for addons
      if (item.addons && item.addons.length > 0) {
        item.addons.forEach(addon => {
          if (productAcceptsDeposit(addon) && addon.paymentOption === DEPOSIT_PAYMENT_OPTION) {
            total += addon.depositAmount * addon.quantity;
          } else {
            total += addon.price * addon.quantity;
          }
        });
      }
    });

    return total + feeTotal();
  };

// Ensure productAcceptsDeposit function is correctly implemented
  const productAcceptsDeposit = (product) => {
    return product.depositAmount > 0;
  };

// Ensure DEPOSIT_PAYMENT_OPTION is correctly defined
  const DEPOSIT_PAYMENT_OPTION = 'deposit';


  const subTotal = () => {
    let total = 0;

    basketItems().forEach(item => {

      // const depositAmount = calculateDeposit(item, item.quantity);
      // updateOrderItemValue(item.id, 'depositAmountIncAddons', depositAmount)

      // Calculate subtotal for the main item
      total += orderItemSubTotal(item)

      // Check and calculate subtotal for each addon if any
      if (item.addons && item.addons.length > 0) {
        item.addons.forEach(addon => {
          total += orderItemSubTotal(addon);
        });
      }
    });

    return total;
  };
const feeTotal = () => {
  let total = 0;


  fees().forEach(element => {
    total += (element?.amount * element?.quantity)
  });
  return total;
}

const total = () => {
  return feeTotal() + subTotal()
}

const totalFullPrice = () => {
    return basketItems().reduce((acc, item) => {
        return acc + item.price * item.quantity;
    }, 0);
}


const updateConfig = (data) => {

  if(typeof data !== 'object') {
    console.warn('Data type must be a nobject');
  }

  setConfig(prevConfig => ({ ...prevConfig, ...data }));
}

const emptyBasket = () => {
  setOrderItems([]);
}


  const value = {
    config,
    basket,
    paymentIntent,
    fees,
    time,
    total,
    dueToday,
    feeTotal,
    subTotal,
    totalFullPrice,
    setTime,
    setConfig,
    setBasket,
    addToBasket,
    addAddon,
    selectedAddons,
    setSelectedAddons,
    removeAddon,
    groupedFees,
    basketItems,
    updateConfig,
    setOrderItems,
    emptyBasket,
    updateOrderItemValue,
    setPaymentIntent,
  };

  return (
    <EcommerceContext.Provider value={value}>
      {children}
    </EcommerceContext.Provider>
  );
};


// Custom hook to use the EcommerceContext and get the `basket` and `addToBasket`
export const useEcommerce = () => {
  const context = useContext(EcommerceContext);
  
  if (!context) {
    throw new Error('useEcommerce must be used within a EcommerceProvider');
  }
  
  
  
  return context;
};


EcommerceProvider.propTypes = {
  children: PropTypes.object
};
