import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { LoyaltyCard } from '../components/LoyaltyCard/LoyaltyCard'
import { StandardSection } from '../../../layout/sections/StandardSection'
import { StyledTitle } from '../../../components/ui/typography/StyledTitle'
import { Section } from '../../../layout/components/LayoutSection'
import { Paragraph } from '../../../components/ui/typography'
import { FadedBgImage } from '../../../components/ui/FadedBgImage'
import { HorizontalFeaturedListLayout } from '../../../layout/ListLayouts/HorizontalFeaturedListLayout'
import TagListLayout from '../../../layout/ListLayouts/TagListLayout'
import { MemberSignupForm } from '../components/MemberSignupForm'
import { useHandleTierChange } from '../js/hooks'
import { useDrawer } from '../../../js/hooks/context/DrawerContext'
import { useCard } from '../js'
import { useSearchParams } from 'react-router-dom'
import { Divider } from '../../../components/ui/Divider'
import { ButtonComponent } from '../../../components/ui/buttons'
import useUpdateTheme from '../../themes/js/hooks/useUpdateTheme'
import {ImpactTitle} from "../../../components/ui/typography/index.js";

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
    height: fit-content;
    /* width: 100%; */
    margin: 0px auto;
    /* padding-bottom: 60px; */
    animation: fadeIn 1s;
    padding: 0px;

    @media screen and (min-width: 768px) {
       height: 100dvh;
    }

`

const CardSection = styled.section`
    @media screen and (min-width: 768px) {
        display: none;
    }
`

export const HtmlTextDiv = styled.div`
    text-align:left;
    color: var(--text-colour);
    max-width:500px;
    margin-top: 10px;

                        
`

const CardPageContentComponent = ({card}) => {

    // SET STATES 
    const [isLoading, setIsLoading ] = useState(sessionStorage.getItem('isLoading'));
    const handleTierChange = useHandleTierChange();
    const { openDrawer, closeDrawer, isOpen, openPreviousDrawer } = useDrawer('top');
    const { selectedTier } = useCard();

    // useUpdateTheme(selectedTier?.theme)

    const searchParams = new URLSearchParams(location.search);
    const goBack = searchParams.get('back');

    useEffect(() => {
        setIsLoading(sessionStorage.getItem('isLoading'))
    }, [sessionStorage.getItem('isLoading')]);

    /**
     * STYLES
     * 
     * 
     */

    const containerStyles = {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        padding: '10px',
        width: '90%',
        maxWidth:'90%',
        zIndex: 2,
        borderRadius: 'var(--border-radius-default)',
        boxSizing:'border-box',
    }

    const standardSectionStyle = {
        maxWidth: '100px'
    }

        
  return (
    <ContentWrapper id={'card-content-container'} style={{paddingBottom: "100px"}}>
        <CardSection id='LoyaltyCard'>
            <StandardSection style={standardSectionStyle} >
                 <FadedBgImage image={selectedTier?.image?.cdnUrl + '?blur=25'} tier={selectedTier} />
                <LoyaltyCard card={card} tier={selectedTier} />
            </StandardSection>
        </CardSection>
        
        <Section id={'card-info'} >
            <ImpactTitle
                size={'1.6rem'}
                // title={card.name}
                main={card.name}
                style={{main: {fontWeight: 900}}}
                />
            <React.Fragment>
                <HtmlTextDiv style={{color:'white', mixBlendMode: 'difference'}} dangerouslySetInnerHTML={{ __html: card?.description }} />
            </React.Fragment>
        </Section>

            {
                card.tiers && card.tiers.length > 1 && 
                <HorizontalFeaturedListLayout
                style={{marginTop: innerWidth > 768 ? '-8rem' : '-7rem', zIndex:'10'}}
                card={'MiniLoyaltyCard'}
                data={card.tiers}
                onClick={(tier) => handleTierChange(tier)}
                />
            }
            {/* Only show this section if the slectedTier has benefits or locations */}
        {   

            <StandardSection
            id={'description'}
            theme={'clipped'}  
            style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width: innerWidth > 768 ? '500px' : '100vw',
                maxWidth: innerWidth > 768 ? '500px' : '100vw',
                marginTop: card.tiers.length > 1 && '0rem',

                }}>
            
            {
                card.tiers.length > 1 &&
                <div style={{margin: '10px 0', marginBottom: '30px'}}>

                    <StyledTitle size={'1.2rem'} style={{color: 'var(--text-colour)'}} title={selectedTier?.name} />

                    <HtmlTextDiv dangerouslySetInnerHTML={{ __html: selectedTier?.description }} />
                </div>
            }


                {
                    selectedTier?.benefits?.length > 0 && (
                        <div style={{
                            width:'100%',
                        }}>
                            <StyledTitle title={selectedTier.benefit_text || card.benefit_text} size={'1.2rem'} style={{color: 'var(--text-colour)'}} />
                            <TagListLayout data={selectedTier.benefits} component={'LargePill'} />
                        </div>
                        )
                }

                {
                    selectedTier?.locations?.length > 0 && (
                        <React.Fragment>
                            <StyledTitle title={selectedTier.location_text || card.location_text} size={'1.2rem'} />
                            <TagListLayout data={selectedTier.locations} component={'LargePill'} />
                        </React.Fragment>
                    )
                }

                <div id='tier-content'></div>

        </StandardSection>
        }

        {

            innerWidth < 768 &&

            <div style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                background: "linear-gradient(to top, var(--primary-colour), transparent)",
                width:"100%",
                padding: "20px",
                height: "100px",
                paddingTop: "40px",
                zIndex: 99
            }}>
                <ButtonComponent
                    variant={'solid'}
                    text={selectedTier?.button_text || 'SIGN UP NOW'}
                    showBack={goBack == 'true' && true}
                    onClick={() => openDrawer(<MemberSignupForm/>, 'SIGN UP')}
                    isLoading={isLoading}
                    colour={'var(--accent-colour)'}

                />

            </div>


        }




  
    </ContentWrapper>
  )
}

export default CardPageContentComponent