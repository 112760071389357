import React from 'react'
import styled from 'styled-components'

const ImageContainer = styled.div`
    display: inline-block;
    position: relative;
    background-color: var(--default-primary-colour);
    height: fit-content;
    width: 100%;
    /* opacity: 0.99; */
    transition: opacity 250ms ease-in-out;
    z-index: 1;
    
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2; /* Make sure the pseudo-element is on top of the image */
        background-image:  linear-gradient(to top, var(--default-primary-colour) 10%, rgba(255, 0, 0, 0));
    }
 
    &:hover {
        opacity: 1;
        transition: opacity 250ms ease-in-out;
    }
`

const Image = styled.img`
    object-fit: cover;
`

export const ImageWithFade = (props) => {
    const { width, height, image } = props;
  return (
    <ImageContainer>
        <Image width={width} height={height} src={image}/>
    </ImageContainer>
  )
}
