import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeRequest } from '../../../../services/axios';
import { AuthContext } from '../../../authentication';
import { useEcommerce } from '../context/EcommerceContext';
import { useDrawer } from '../../../../js/hooks/context/DrawerContext';
import { Buffer } from 'buffer';
import { paths } from '../../../../js/helpers/paths';
import {useHandleError} from "../../../error-handling/js/hooks/index.js";
import {getBookingId} from "../../../bookings/js/utils/storage.js";
import {useAffiliate} from "../../../.feature_folder/js/hooks/useAffiliate.jsx";
import { toast } from 'react-toastify';

/**
 * Custom hook to handle the checkout process.
 *
 *
 * @return {Object} - { handleClickCheckout, checkoutLoading, checkoutMessage }
 */
const useHandleClickCheckout = () => {
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [checkoutMessage, setCheckoutMessage] = useState(null);


  const navigate = useNavigate();
  const { header } = useContext(AuthContext)
  const { setBasket, time } = useEcommerce();
  const { closeDrawer } = useDrawer('top');
  const { showErrorToast } = useHandleError();
  const {getAffiliateBySlug} = useAffiliate();


  const {
    basket,
  } = useEcommerce();

  const handleClickCheckout = async (event, business, nextAction) => {

    const affiliates = getAffiliateBySlug(event?.slug);

    if (!basket.orderItems || basket.orderItems.length === 0) {
      toast.warn('Your basket is empty');
      return;
    }

    try {
      setCheckoutLoading(true);

      const data = {
        event_id: event?.id,
        business_id: business?.id || business, // because in productPage we are just passing the business_id
        order_items: basket.orderItems,
        fees: basket?.orderFees,
        metadata: {
          bookingId: time?.id
        },
        affiliates: affiliates
      };

      const response = await makeRequest('POST', `basket/${basket.id}/start-checkout`, data, header(), null);


      setBasket(prevBasket => ({
        ...prevBasket,
        ...response.data.basket,
        paymentIntent: response.data.stripe.intent_id
      }));


      if(response?.status !== 'success') {
        showErrorToast(response.data.message)
      }

      setCheckoutLoading(false)

      closeDrawer();

      const id = response.data.basket.id.toString();
      const base64Id = Buffer.from(id).toString('base64');

      if (response.info != null) sessionStorage.setItem('checkoutMessage', response.info);

      // if (nextAction === 'checkout') {
        navigate(`/${paths['checkout']}/${base64Id}`);
      // }

      return response;

    } catch (error) {
      console.log('error', error);
      showErrorToast(error.response.data.error_message || error.message)
      setCheckoutLoading(false)
      setCheckoutMessage(error.message);
    } 
    
  };

  return {
    handleClickCheckout,
    checkoutLoading,
    checkoutMessage
  };
};


export default useHandleClickCheckout