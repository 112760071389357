import React from 'react';
import styled from 'styled-components';
import { theme } from '../../js/Helper';
import { Divider } from '../ui/Divider';

const DefaultHeroContainer = styled.div`
  display: flex;
  min-height: 100svh;
  height: fit-content;
  width: 100%;
  box-sizing: border-box;

  background-color: var(--primary-colour);
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 0px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(rgba(0, 0, 0, 0.2), var(--primary-colour));
      z-index: 1;
    }

    & > * {
      position: relative;
      z-index: 2;
    }
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row-reverse;
  }
`;

const HeroContentContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 0px;
  min-height: 100dvh;
  height: fit-content;

  @media screen and (max-width: 768px) {
    /* margin-bottom: ${props => props.left ? '-200px' : '0px'}; */
  }


  @media screen and (min-width: 768px) {
    flex-direction: row;
    border-radius: var(--border-radius-default);
    min-height: fit-content;
    max-width: 75vw;
    padding: 0px;
  }

  @media screen and (min-width: 1200px) {
    width: 75vw;
  }

  @media screen and (min-width: 1920px){
    max-width: 70vw;
  }
`

const Container = styled.div`
position: relative;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
flex: 1;
padding-bottom: ${props => props.right ? '20px' : '0px'};
padding-left: ${props => props.right ? '20px' : '0px'};
padding-right: ${props => props.right ? '20px' : '0px'};
z-index: ${props => props.right ? '20' : '0'};
box-sizing: border-box;
background-color: var(--primary-colour);
/* overflow: hidden; */

// only here because of the imgix params shifted the image
margin-bottom: ${props => props.right ? '-1px' : '0'};

#description  {
  font-weight: bolder;
  line-height: normal;
  color: var(--text-colour);
}

@media screen and (min-width: 768px){
  
  justify-content: center;
  align-items: center;
  box-shadow: 10px 10px 20px rgba(0,0,0,0.1), -5px -5px 20px rgba(0,0,0,0.2);
  padding: ${props => props.right ? '0.75rem ': '0'};
  background-color: ${props => props.right ? 'var(--primary-colour)' : null};
  border-top-left-radius: ${props => props.right ? '0px' : 'var(--border-radius-medium)'};
  border-bottom-left-radius: ${props => props.right ? '0px' : 'var(--border-radius-medium)'};
  border-top-right-radius: ${props => props.right ?  'var(--border-radius-medium)' : '0px'};
  border-bottom-right-radius: ${props => props.right ?  'var(--border-radius-medium)' : '0px'};
  background-color: ${props => props.right ? 'rgba(0,0,0,0.3)' : null};
  backdrop-filter: ${props => props.right ? 'blur(15px)' : null};
}
  
@media screen and (min-width: 1024px) {
  #description  {
    width: 400px;
  }

}
`

const ChildrensContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  z-index: 2;
  /* outline: solid 2px red; */
`

export const DefaultHero = (props) => {

  const { image, info, title, description, children } = props

  return (
      <DefaultHeroContainer image={`${image?.props?.image}?blur=50`} id='container-component'>
          <HeroContentContainer id='hero-container'>

          <Container name='left' left>
              {image}
            </Container>

            <Container name='right' right>
              <div style={{marginTop: innerWidth <= 768 && '-80px', minWidth:'100%'}}>
                {title}
                {/* <Divider /> */}
                <div style={{display:'flex', justifyContent:'center', width:'100%'}}>
                  {description}
                </div>
                {info}
                <ChildrensContainer>
                  {children}
                </ChildrensContainer>
              </div>
            </Container>

     


          </HeroContentContainer>



      </DefaultHeroContainer>
  );
};
