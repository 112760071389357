import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { ImageComponent } from './ui/images/ImageComponent'
import { Title } from './ui/typography'
import {useApiQuery} from "../hooks/customHooks.js";

const GalleryContainer = styled.div`

    width: 100dvw;
    max-width: 100dvw;
    margin-left: -20px;
    z-index: 299;
    

    @media screen and (min-width: 1024px){
        display: flex;
        width: 50vw;
        border-radius: 10px;
        align-self: center;
    }

  

`

const ImagesContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    min-height: 100px;
    height: fit-content;
    width: 100%;
    max-width: 100dvw;
    /* margin-top: 20px; */
    padding-left: 30px;
    padding-right: 30px;
    border-radius: var(--border-radius-default);
    box-sizing: border-box;
    margin-bottom: 50px;
    overflow: scroll;
    scrollbar-width: none; // for Firefox
    -ms-overflow-style: none; // for Internet Explorer and Edge
    scroll-snap-type: x mandatory; // enables x-axis scroll snapping
    
    /* Hide the scrollbar */
    &::-webkit-scrollbar {
    display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;

    }

    &::-webkit-scrollbar-thumb {
        display: none;
    }
    
    @media screen and (min-width: 1024px) {
        align-items: flex-start;
        overflow: unset;
        justify-content: flex-start;
        overflow: scroll;
        padding: 0px;
        height: fit-content;
    }
    `


export const DefaultGallery = ({galleryId = 1 }) => {

    const [gallery, setGallery] = useState([])

    if(!galleryId) return null;

    useEffect(() => {
        console.log('gallerySet', gallery)
    }, [gallery])

    const {data, isSuccess, error, refetch, isLoading} = useApiQuery(`galleries/${galleryId}`, data => {
        if(!data?.success) return;

        setGallery(data?.data)
    })

    if(error || gallery?.length === 0) return null;

    return (
        <GalleryContainer>
        {/* <Title text="Gallery"/> */}
        <ImagesContainer>
            {
                gallery?.files?.map((image, index) => {
                    return (
                        <ImageComponent
                            key={index}
                            src={`${image.cdnUrl}`}
                            width={'200rem'}
                        />
                    )
                })
            }
        </ImagesContainer>
    </GalleryContainer>
  )
}
