import React, {useEffect, useState, useRef} from 'react';
import styled from "styled-components";
import {useApiQuery} from "../../../hooks/customHooks.js";
import {useNavigate, useParams} from "react-router-dom";
import {useHandleError} from "../../error-handling/js/hooks/index.js";
import {Loading} from "../../../components/ui/Loading.jsx";
import {FullPageContainer} from "../../../layout/styles/layoutStyles.js";
import {HeroImage} from "../../../components/heroes/HeroImage.jsx";
import {changeCSSVariableProperty, updateTheme} from "../../themes/js/utils.js";
import {RectangleCardComponent} from "../../../components/ui/cards/RectangleCardComponent/RectangleCardComponent.jsx";
import {ImageComponent} from "../../../components/ui/images/ImageComponent.jsx";
import {DividerWithText} from "../../../components/ui/LogoWall/DividerWithText.jsx";
import {ButtonComponent} from "../../../components/ui/buttons/index.js";
import {useDrawer} from "../../../js/hooks/context/DrawerContext.jsx";
import {ProductList} from "../../ecommerce/products/components/index.js";
import GuestlistForm from "../components/GuestlistForm.jsx";
import {useEvent} from "../js/hooks/index.js";
import {Footer} from "../../../layout/components/Footer.jsx";
import {Paragraph} from "../../../components/ui/typography/index.js";
import card from "../../cards/pages/Card.jsx";
import { FiMoreVertical, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';
import { API_URL, PORTAL_URL } from '../../../js/Helper.js';
import { LoyaltyCard } from '../../cards/components/LoyaltyCard/LoyaltyCard.jsx';
import LogoComponent from '../../../components/ui/LogoComponent.jsx';

const PageContainer = styled(FullPageContainer)`
    padding-top: 0;
`

const ContentContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 100%;
    width: 100%;
    gap: 20px;
    padding: 0 20px;
    padding-top: 50px;
    
`

const ProductListContainer = styled.div`
    width: 100%;
    padding: 20px 20px 40px;
`

const MemberContainer = styled.div`
    width: 100%;
    padding: 10px;
    border-radius: var(--border-radius-default);
    background: rgba(0,0,0,0.1);
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--shadow-primary);
    transition: box-shadow 0.3s ease;
    border: 1px solid rgba(255,255,255,0.025);
    
    &:hover {
        box-shadow: var(--shadow-hover-default);
    }
    
    &:focus {
        box-shadow: var(--shadow-focus);
    }
    
    span {
        font-weight: bolder;
    }
`

const MemberInfo = styled.div`
    flex: 1;
`

const DropdownContainer = styled.div`
    position: relative;
`

const DropdownButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    color: var(--text-colour);
    transition: opacity 0.2s;

    &:hover {
        opacity: 0.7;
    }
`

const DropdownMenu = styled.div`
    position: absolute;
    right: 0;
    top: 100%;
    background-color: var(--primary-colour);
    border: 1px solid var(--accent-colour);
    border-radius: var(--border-radius-default);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    min-width: 150px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;

    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
`

const DropdownItem = styled.button`
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text-colour);
    transition: background-color 0.2s;

    &:hover {
        background-color: rgba(var(--accent-colour-rgb), 0.1);
    }
`

const AnimatedMemberContainer = motion(MemberContainer);

const StatSection = styled.div`
  width: 100%;
  margin: 20px 0;
  padding: 5px 0;
`;

const StatContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 15px;
`;

const StatCounter = styled.div`
  background: rgba(var(--accent-colour-rgb), 0.05);
  border: 1px solid rgba(var(--accent-colour-rgb), 0.2);
  border-radius: var(--border-radius-default);
  padding: 12px;
  text-align: center;
`;

const StatLabel = styled.div`
  font-size: 0.8rem;
  color: var(--text-colour);
  opacity: 0.7;
  margin-bottom: 4px;
`;

const StatNumber = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--accent-colour);
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: var(--text-colour);
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 0;
`;

const StatsToggleContainer = styled.div`
  width: 100%;
`;

const CardLis = () => {
    const params = useParams();
    const tierId = params.tierId;
    const { showErrorPage } = useHandleError();
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const [openDropdown, setOpenDropdown] = useState(null);
    const dropdownRef = useRef(null);
    const [dropdownStates, setDropdownStates] = useState({});
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
    const [showStats, setShowStats] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchQuery(searchQuery);
        }, 100); // 300ms delay

        return () => clearTimeout(timer);
    }, [searchQuery]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenDropdown(null);
                setDropdownStates({});
            }
        };

        if (openDropdown !== null) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openDropdown]);

    const url = `https://api.nctrnl.app/portal/members/${tierId}`
    // const url = `http://localhost:8000/portal/members/${tierId}`

    const { isLoading, isSuccess, error, data } = useApiQuery(url, (data) => {
        console.log(data)
        changeCSSVariableProperty('--accent-colour', data?.data?.[0]?.card_tier?.theme?.accent_colour);
        // updateTheme(data?.data?.[0]?.card_tier?.theme);
    });


    if (isLoading || !data || data.status !== 'success') return <Loading />;
    if (error) showErrorPage(error);
    if (!isSuccess) showErrorPage(error);

    // updateTheme(events?.[0]?.theme);

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const cardholders = data?.data;
    const card = data?.data?.[0]?.card_tier?.card;
    const tier = data?.data?.[0]?.card_tier;
    const count = data?.count;
    const totalFollowers = data.total_followers;
    const foodOptionCounts = data.food_option_counts;
    const dessertOptionCounts = data.dessert_option_counts;

    const generalStats = [
        { label: "Sign Up Last 3 Days", value: count },
        { label: "Total Followers", value: totalFollowers },
    ];

    const foodStats = Object.entries(foodOptionCounts).map(([option, count]) => ({
        label: ` ${option}`,
        value: count
    }));

    const dessertStats = Object.entries(dessertOptionCounts).map(([option, count]) => ({
        label: `${option}`,
        value: count
    }));

    const filteredCardholders = cardholders.filter(cardholder =>
        cardholder.user?.first_name?.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
    ).sort((a, b) => 
        a.user?.first_name?.toLowerCase().localeCompare(b.user?.first_name?.toLowerCase())
    );



    const actions = [
        { label: 'Remove', action: (cardholder) => handleRemove(cardholder) },
        { label: 'Send Ticket', action: (cardholder) => handleSendPasscode(cardholder) },
    ];

    const handleRemove = (cardholder) => {
        if (window.confirm(`Are you sure you want to remove ${cardholder.user.first_name}?`)) {
            console.log(`Removing ${cardholder.user.first_name}`);
            // Implement removal logic here
        }
    };

    const handleSendPasscode = (cardholder) => {
        console.log(`Sending passcode to ${cardholder.user.first_name}`);
        // Implement send passcode logic here
    };

    const toggleDropdown = (cardholderIndex) => {
        setDropdownStates(prevStates => ({
            ...prevStates,
            [cardholderIndex]: !prevStates[cardholderIndex]
        }));
        setOpenDropdown(openDropdown === cardholderIndex ? null : cardholderIndex);
    };

    const handleActionClick = (action, cardholder) => {
        action.action(cardholder);
        setOpenDropdown(null);
    };

    const toggleStats = () => {
        setShowStats(!showStats);
    };

    return (
        <>
            <PageContainer>

      
                <ContentContainer style={{ gap: '5px', zIndex: 10 }}>

                    <LogoComponent  style={{width:'100%', display:'flex', justifyContent:'center', marginBottom:'20px'}} showLogo={true}/>

                    {card && (
                        <LoyaltyCard
                            style={{width:'100%'}}
                            card={card}
                            // member={filteredCardholders[0]}
                            tier={tier}
                        />
                    )}

                    <StatsToggleContainer>
                        <ToggleButton onClick={toggleStats}>
                            {showStats ? <FiChevronUp /> : <FiChevronDown />}
                            {showStats ? 'Hide Stats' : 'Show Stats'}
                        </ToggleButton>
                        {showStats && (
                            <>

                            <DividerWithText text="General Stats" />
                                <StatSection>
                                    <StatContainer>
                                        {generalStats.map((stat, index) => (
                                            <StatCounter key={index}>
                                                <StatLabel>{stat.label}</StatLabel>
                                                <StatNumber>{stat.value}</StatNumber>
                                            </StatCounter>
                                        ))}
                                    </StatContainer>
                                </StatSection>

                                <DividerWithText text="Food Stats" />

                                <StatSection>
                                    <StatContainer>
                                        {foodStats.map((stat, index) => (
                                            <StatCounter key={index}>
                                                <StatLabel>{stat.label}</StatLabel>
                                                <StatNumber>{stat.value}</StatNumber>
                                            </StatCounter>
                                        ))}
                                    </StatContainer>
                                </StatSection>

                                <DividerWithText text="Dessert Stats" />

                                <StatSection>
                                    <StatContainer>
                                        {dessertStats.map((stat, index) => (
                                            <StatCounter key={index}>
                                                <StatLabel>{stat.label}</StatLabel>
                                                <StatNumber>{stat.value}</StatNumber>
                                            </StatCounter>
                                        ))}
                                    </StatContainer>
                                </StatSection>
                            </>
                        )}
                    </StatsToggleContainer>

                    <input
                        type="text"
                        placeholder="Search name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{
                            padding: '10px',
                            width: '100%',
                            marginBottom: '20px',
                            borderRadius: 'var(--border-radius-default)',
                            border: '1px solid',
                            borderImage: 'var(--accent-colour) 1',
                            backgroundColor: 'var(--primary-colour)'
                        }}
                    />

                    <AnimatePresence>
                        {filteredCardholders.map((cardholder, index) => (
                            <AnimatedMemberContainer
                                key={cardholder.id}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.3 }}
                            >
                                <MemberInfo>
                                    <p><span>Name: </span> {cardholder.user.first_name}</p>
                                    {cardholder.user.social_accounts && cardholder.user.social_accounts.map((account, index) => (
                                        <p key={index}>
                                            <span>{account.platform}: </span>
                                            <a href={`https://${account.platform === 'tiktok' ? 'tiktok' : 'instagram'}.com/${account.username}`} target="_blank" rel="noopener noreferrer">
                                                {account.username} {account.followers ? `(${account.followers})` : ''}
                                            </a>
                                        </p>
                                    ))}
                                    <p><span>Food Option: </span> {cardholder.food_option}</p>
                                    <p><span>Dessert Option: </span> {cardholder.dessert_option}</p>

                                </MemberInfo>
                                <DropdownContainer ref={dropdownRef}>
                                    <DropdownButton onClick={() => toggleDropdown(index)}>
                                        <FiMoreVertical />
                                    </DropdownButton>
                                    <DropdownMenu className={dropdownStates[index] ? 'active' : ''}>
                                        {actions.map((action, actionIndex) => (
                                            <DropdownItem
                                                key={actionIndex}
                                                onClick={() => handleActionClick(action, cardholder)}
                                            >
                                                {action.label}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </DropdownContainer>
                            </AnimatedMemberContainer>
                        ))}
                    </AnimatePresence>
                </ContentContainer>
            </PageContainer>
            <Footer />
        </>
    );
};

export default CardLis;