import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LogoComponent from '../../components/ui/LogoComponent';
import {Paragraph} from "../../components/ui/typography/index.js";

// #region constants

// #endregion

// #region styled-components
const HeaderContainer = styled.section`
    display: grid;
    place-items: center;
    height: 100svh;
    min-height: 50px;
    padding-top: 20px;
`
// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

/**
 * 
 */
const Header = () => {
    return (
        <HeaderContainer>
            <div>
            <LogoComponent />
            <Paragraph style={{textAlign: 'center'}}>Under Construction</Paragraph>
            </div>
        </HeaderContainer>
    )
}

Header.propTypes = propTypes;
// #endregion

export default Header;
