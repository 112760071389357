import React from 'react'
import styled from 'styled-components';
import { FeaturedCardComponent } from '../../components/ui/cards/FeaturedCardComponent';
import { cardComponentMap } from '../../components/ui/cards/cardComponentMap';
import { InstructionTitle } from '../../components/ui/typography/InstructionTitle';

const ListContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 10px;
    //width: 100vw;
    //max-width: 100vw;
    height: fit-content;
    padding: 20px 20px;
    max-width: 100vw;
    overflow-x: auto; // changed from scroll to auto
    overflow-y: hidden;
    scrollbar-width: none; // for Firefox
    -ms-overflow-style: none; // for Internet Explorer and Edge
    scroll-snap-type: x mandatory; // enables x-axis scroll snapping
    
    box-sizing: border-box;
    z-index: 3;


    /* Hide the scrollbar */
    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;

    }

    &::-webkit-scrollbar-thumb {
        display: none;
    }

    @media screen and (min-width: 1024px){
        width: unset;
        justify-content: center;
        align-items: center;
    }
`

export const HorizontalFeaturedListLayout = ({ data, card, metadata, model, onClick, style }) => {


  return (
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:"center", marginTop:'10px', ...style}}>

            {/* <InstructionTitle title={'WATCH OUR VIDEOS'} style={{marginBottom:'-23px'}} /> */}

            <ListContainer name={'horizontal-list-container'} >
                {
                    data && data.reverse().map((item, index) => {

                        const CardComponent = card ? cardComponentMap[card] : cardComponentMap['FeaturedCardComponent']

                        return (
                            <CardComponent
                            key={index}
                            data={item}
                            src={item?.cdnUrl}
                            height={metadata?.height}
                            width={metadata?.width}
                            numberOfItems={data.length}
                            data_model={model}

                            card={item}
                            tier={item.default_tier}
                            onClick={onClick}
                            />
                        )
                    })
                }
            </ListContainer>
        </div>


  )
}
