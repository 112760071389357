import React, {useState} from 'react';
import {FullPageAbsoluteContainer} from "../../../layout/styles/layoutStyles.js";
import {Product} from "../../ecommerce/products/components/index.js";
import {useApiQuery} from "../../../hooks/customHooks.js";
import SliderComponent from "../../../components/ui/SliderComponent.jsx";

const Sandbox = () => {
    // const { data } = useApiQuery('products/232?with=fileLinks');
    // if(!data) return null;

    const [sliderValue, setSliderValue] = useState(50);

    const handleSliderChange = (newValue) => {
        setSliderValue(newValue);
        console.log(newValue / 0.25);
    };

    return (
        <FullPageAbsoluteContainer style={{height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
            {/*<Product product={data} />*/}
            <SliderComponent 
                // prefix={'£'}
                // suffix={'M EXPOSURE'}
                type={'currency'}
                typeOptions={{currency: 'GBP'}}
                value={sliderValue} 
                max={33000} 
                min={0} 
                step={0.25}
                onChange={handleSliderChange}
            />
        </FullPageAbsoluteContainer>
    );
};

export default Sandbox;
