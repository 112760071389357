import React from 'react';
import styled from 'styled-components';
import { Section } from '../../../layout/components/LayoutSection';
import { ContentWrapper } from '../../moments/pages/CampaignPurchaseLandingPage';
import { ImpactTitle, Paragraph } from '../../../components/ui/typography';

const DeliverablesSection = ({ collection, handleDeliverableClick, selectedDeliverables = [], openDrawer }) => {
  return (
    <Section 
      id={'deliverables'}
      clip={"true"}
      style={{
        background: 'var(--ticket-section-bg-colour)',
        zIndex: 1,
        paddingBottom: '200px',
        paddingTop: '150px',
        height: 'fit-content'
      }}
    >
      <ContentWrapper>
        <ImpactTitle main={'Deliverables'} style={{ background: 'var(--primary-colour)' }} />
        <Paragraph>
          Below is a list of deliverables we have provided in exchange for everything above. These deliverables are required for your participation.
        </Paragraph>
        <Paragraph>
          Any deliverable without a price is included when you purchase the exposure you chose above.
        </Paragraph>
        <ul>
          {collection.map((deliverable, index) => (
            <li style={{color: 'var(--text-colour)'}} key={index}>
              {deliverable}
            </li>
          ))}
        </ul>
      </ContentWrapper>
    </Section>
  );
};

export default DeliverablesSection;
