import React from 'react';
import styled from 'styled-components';
import {ButtonComponent} from "./ButtonComponent.jsx";

const Wrapper = styled.div`
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    //padding: 20px 20px;
    

    // Pseudo-element for gradient overlay
    &::after {
        content: "";
        position: absolute;
        top: 0;
        //left: 0;
        //min-width: 100vw;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, var(--primary-colour), transparent);
        z-index: 0; // Ensure the overlay is above the image
        pointer-events: none; // Allows interaction with underlying elements
    }
    
`

const Content = styled.div`
    padding: 20px;
    padding-top: 100px;
`
const CallToActionButton = ({ text, style }) => {
    return (
        <Wrapper>
            <Content>
                <ButtonComponent
                    variant={'solid'}
                    text={text}
                    style={style}
                />
            </Content>

        </Wrapper>
    );
};

export default CallToActionButton;