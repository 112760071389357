import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CardContainer, CardInfo, CardTitle } from './rectangleCardComponent.style'

export const RectangleCardComponent = ({
                                           title, description, quickLinks, image,
                                           onClick,
                                           handleClick
                                       }) => {

    const navigate = useNavigate();

    const handleCardClick = () => {
        if (handleClick) {
            handleClick();
        } else if (onClick) {
            onClick();
        }
    };

    return (
        <CardContainer id={title} onClick={handleCardClick}>
            {image}
            <CardInfo id={title + '--info'}>
                <div>
                    <CardTitle>{title}</CardTitle>
                    <p id={'description'}>{description}</p>
                </div>
                {quickLinks}
            </CardInfo>
        </CardContainer>
    )
}
