import React, {useContext, useEffect, useState} from 'react'
import {useElements, useStripe,} from '@stripe/react-stripe-js';
import {toast} from 'react-toastify';
import {AuthContext} from '../../../authentication';
import {useEcommerce} from '../../js/context/EcommerceContext';
import {useHandleStripePayment} from '../../services/stripe/hooks/useHandleStripePayment';
import StripePaymentButtons from '../../services/stripe/components/StripePaymentButtons';
import {useNavigate} from 'react-router-dom';
import {useHandleError} from "../../../error-handling/js/hooks/index.js";

export const CheckoutForm = ({
    intent, amount, onSuccess
  }) => {

  if(!intent) {
    // showToastError('unable to process payment');
  }


  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated } = useContext(AuthContext);
  const { config, basket } = useEcommerce();
  const [paymentRequest, setPaymentRequest] = useState();
  const navigate = useNavigate();

  const {
    confirmPayment,
    formValidation,
    getClientSecret,
    isPaymentAllowed,
    hasCompletedQuestions,
  } = useHandleStripePayment();

  const stripe = useStripe();
  const elements = useElements();
  const { showErrorToast } = useHandleError();


  useEffect(() => {

    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'GB',
      currency: 'gbp',
      total: {
        label: 'Total',
        amount: amount
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {


      if (result) {
        setPaymentRequest(pr)        
      }

      // console.log('result', result)
      pr.on('paymentmethod', async (ev) => {

        const { clientSecret, order, user, error } = await getClientSecret(intent)

        if(error) {
          ev.complete('fail')
          showErrorToast(error)
          return;
        }

        // Confirm the PaymentIntent without handling potential next actions (yet).
        const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
          clientSecret,
          {payment_method: ev.paymentMethod.id},
          {handleActions: false}
        );

        // TODO - we can update the order status from here
      
        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          console.log('error', confirmError);
          showErrorToast(confirmError)
          ev.complete('fail');
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete('success');
          // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const {error} = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              showErrorToast(error)
              // The payment failed -- ask your customer for a new payment method.
            } else {
              // The payment has succeeded -- show a success message to your customer.
              
              toast.success('Payment Confirmed')
              navigate(`/confirmation/${order?.id}`)

            }
          } else {

            toast.success('Payment Confirmed')
            navigate(`/confirmation/${order?.id}`)
          }
        }
      });


    }).catch((error) => {
      console.log('Can not make payment', error)
      showErrorToast('Can not payment payment: ' + error.message)
    });


  }, [stripe]);


  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true)

      if(isPaymentAllowed(basket.isPaymentAllowed, isAuthenticated) === false) return;

      if(!hasCompletedQuestions(basket?.hasQuestions, basket?.questionsComplete)) return;
      if(!await formValidation(elements)) return;
      
      const { clientSecret, order, user } = await getClientSecret(intent)

      await confirmPayment(clientSecret, order, user, stripe, elements)


      // setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      showErrorToast(error)
      // toast.warn(error.message)
      console.log('Error in Handling Payment',  error)
    }
  };


  return (
    <form id='payment' onSubmit={handleSubmit}>
      <StripePaymentButtons
      paymentRequest={paymentRequest}
      stripe={stripe}
      elements={elements}
      amount={amount}
      isLoading={isLoading}
      />
    </form>
  );
};
