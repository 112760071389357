import React from 'react';
import PropTypes from 'prop-types';
import {BoldTitle, InfoSection, Paragraph, ShortTitle} from "../../../components/ui/typography/index.js";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100vh;
    max-width: 96vw;
    gap: 20px;
`
const InfoSectionContainer = styled.div`
    display: flex;
    gap: 10px;
    overflow: scroll;
    margin-bottom: 30px;
    //padding: 20px;
    
    /* Hide scrollbar for Chrome, Safari and Edge */
    ::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for Firefox */
    scrollbar-width: none;

    /* Ensures the element is still scrollable */
    -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
`


const InfoContainer = styled.div`
    //background-color: red;
    padding: 20px;
    border-radius: 10px;
    background-color: black;
`

const GenericPage = ({  }) => {

    const style = {
        container: {
            marginTop: '-80px',
            // marginBottom: '100px'
        }
    }

    return (
        <Container>
            <BoldTitle title={'Revenue'} subtitle={'Streams'} style={style} />
            <Paragraph style={{marginBottom: '20px'}}>We're not just creating revenue streams; we're unleashing torrents of profit:</Paragraph>

            <InfoContainer>
                <InfoSectionContainer>
                    <InfoSection subtitle={'Booking Fees'} paragraph={'Skimming 8-12% off every transaction, turning FOMO into FUNDS\n'} />
                    <InfoSection subtitle={'Membership Magic'} paragraph={'3-5% from each subscription, transforming loyalty into liquid assets\n'} />
                    <InfoSection
                        subtitle={'Student Takeover'}
                        paragraph={'£5/month from the young and restless (That\'s a cool £456,000/year from Birmingham alone. Multiply that across cities, and we\'re talking empires)\n'}
                    />


                    {/*<InfoSection subtitle={'Loyalty Programs on Steroids'} paragraph={'Rewards that turn customers into fervent brand evangelists'} />*/}

                </InfoSectionContainer>

                <InfoSectionContainer>
                    <InfoSection
                        subtitle={'Nocturnal Pay'}
                        paragraph={'0.6% + 20p per transaction. Small change? Think again. It\'s death by a thousand cuts for our competitors\n'}
                    />
                    <InfoSection
                        subtitle={'Platform Partnerships'}
                        paragraph={'Sharing the spoils with industry titans, because a rising tide lifts all boats (especially ours)\n'}
                    />
                    <InfoSection
                        subtitle={'Business Alchemy'}
                        paragraph={'Turning partnerships into profit-sharing goldmines\n'}
                    />
                </InfoSectionContainer>
            </InfoContainer>


        </Container>
    );
};

GenericPage.propTypes = {
    // components: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default GenericPage;