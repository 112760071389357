import React, {useEffect, useState} from 'react'
import { useEcommerce } from '../js/context/EcommerceContext';
import { useHandleClickCheckout } from '../js/hooks';
import { BasketContainer, Header } from './styles/basket.styles';
import { BasketFees, BasketItems } from './components';
import { InfoItemComponent } from '../../../components/ui/InfoItemComponent';
import { convertToCurrency } from '../../../js/helpers/helpers';
import { ButtonComponent } from '../../../components/ui/buttons';
import { useDrawer } from '../../../js/hooks/context/DrawerContext';
import { Paragraph } from "../../../components/ui/typography/index.js";
import { DividerWithText } from "../../../components/ui/LogoWall/DividerWithText.jsx";
import { useEvent } from "../../events/js/hooks/index.js";
import PackForm from '../../packs/components/PackForm';
import {LoadingAnimationComponent} from "../../../components/ui/LoadingAnimationComponent.jsx";

function Basket({
    title,
    display,
}) {
    const { event } = useEvent();
    const {
        total,
        basket,
        setBasket,
        dueToday,
        groupedFees
    } = useEcommerce();

    const {
        checkoutLoading,
        handleClickCheckout,
    } = useHandleClickCheckout(event, event?.business);

    const { anchor, openDrawer } = useDrawer();
    const [basketIsRecalculating, setBasketIsRecalculating] = useState(false);

    useEffect(() => {
        window.addEventListener('quantityChange', quantityChangeEventListener);
        window.addEventListener('quantityChanged', quantityChangedEventListener);

        // return () => {
        //     window.removeEventListener('quantityChange', quantityChangedEventListener);
        // }
    }, []);

    const quantityChangeEventListener = (event) => {
        const { id, quantity } = event.detail;
        setBasketIsRecalculating(true)
    }

    const quantityChangedEventListener = (event) => {
        const { id, quantity } = event.detail;
        setBasketIsRecalculating(false)
    }


    let style = {};

    if(anchor !== 'top' || anchor !== 'bottom') {
        style.backgroundColor = 'transparent'
    }

    if(basket?.orderItems?.length === 0) {
        return <Paragraph>No items in basket</Paragraph>
    }

    const amountDueToday = basket.total // TODO - cchange this
    const totalAmountDue = basket.total  // TODO - change this

    const allItemsDeliverable = basket?.orderItems?.every(item => item.category === "deliverable" || item.category === "exposure" || item.category === "sponsor package" || item.category === "influencer_deliverable");


    const handleButtonClick = () => {
        if (allItemsDeliverable) {
            openDrawer(
                <PackForm
                    name="complete-order"
                    inputs={[
                        { name: 'business_name', label: 'Business Name', type: 'text', required: true },
                        { name: 'representative_name', label: 'Representative Name', type: 'text', required: true },
                        { name: 'email', label: 'Email', type: 'email', required: true },
                        { name: 'website', label: 'Website', type: 'url', required: false },
                        {name: 'form_id', type: 'hidden', value: 1},
                        {name: 'campaign_id', type: 'hidden', value: 1},
                        {name: 'basket', type: 'hidden', value: JSON.stringify(basket.orderItems)}

                    ]}
                    submitButton={{ text: 'Complete Order' }}
                    onSuccess={() => {
                        // Handle success (e.g., close drawer, show confirmation)
                    }}
                    endpointUrl={'campaign-submissions'}
                />,
                'Complete Your Order'
            );
        } else {
            handleClickCheckout(event, event?.business, allItemsDeliverable ? null : 'checkout');
        }
    };

    return (
        <BasketContainer display={display || 'flex'} style={style} id='inline-basket'>
            <DividerWithText text={'Items'} />

            <BasketItems />

            { groupedFees().length > 0 && (
                <>
                    <DividerWithText text={'Fees'} />
                    <BasketFees />
                </>
            )}

            <DividerWithText text={'Total'} />
            <InfoItemComponent><p>Total</p><p>{ basketIsRecalculating ? <LoadingAnimationComponent/> : convertToCurrency(totalAmountDue)}</p></InfoItemComponent>
            { amountDueToday !== totalAmountDue && <InfoItemComponent><p>Due Today</p>{convertToCurrency(amountDueToday)}</InfoItemComponent> }
            { amountDueToday !== totalAmountDue && (
                <Paragraph>Remaining balance of {convertToCurrency(totalAmountDue - amountDueToday)} required 2 weeks before the event.</Paragraph>
            ) }
            <br />

            <ButtonComponent
                variant={'solid'}
                isLoading={checkoutLoading}
                text={allItemsDeliverable ? 'SUBMIT ORDER' : 'CHECKOUT'}
                isDisabled={basketIsRecalculating}
                onClick={handleButtonClick}
            />

        </BasketContainer>
    )
}

export default Basket
