import React from 'react';
import styled from 'styled-components';
import { IntroSection as IntroSectionComponent } from '../pages/IntroSection';

const IntroSection = ({ campaign, backgroundImage }) => {
  return (
    <IntroSectionComponent
      title={campaign.name}
      subtitle={campaign.subtitle || 'Be Part Of The'}
      guest={'Lil Meech'}
      guestRole={'Hosted By'}
      logo={'/loud-logo.png'}
      backgroundImage={backgroundImage?.cdnUrl}
    />
  );
};

export default IntroSection;
