import React from 'react';
import styled from 'styled-components';
import { Section } from '../../../layout/components/LayoutSection';
import { ContentWrapper } from '../../moments/pages/CampaignPurchaseLandingPage';
import { ImpactTitle, Paragraph } from '../../../components/ui/typography';
import { ImageComponent } from '../../../components/ui/images/ImageComponent';
import ProductCollection from '../../collections/components/ProductCollection';

const MenuSection = ({ menu, handleMenuClick, selectedMenuItems }) => {
  return (
    <Section
      id={'menu'}
      style={{
        zIndex: 1,
        paddingBottom: '150px',
        paddingTop: '100px',
        height: 'fit-content'
      }}
    >
      <ContentWrapper>
        <ImpactTitle subHeader={'Food Served By'} header="Event Menu" />
        <ImageComponent style={{ marginBottom: '2rem 0' }} src={'/JerkJunctionLogo.png'} alt={'Loud Clothing Logo'} width={150} />
        <Paragraph>Indulge in our carefully curated menu for the evening: Please select 1 main and 1 dessert.</Paragraph>
        {<ProductCollection data={menu} onClickFunction={handleMenuClick} selectedList={selectedMenuItems} />}
      </ContentWrapper>
    </Section>
  );
};

export default MenuSection;
