import { useCallback } from 'react';
import { useEcommerce } from '../context/EcommerceContext';
import { useDrawer } from '../../../../js/hooks/context/DrawerContext';
import { toast } from 'react-toastify';
import axios from "axios";
import {API_URL} from "../../../../js/Helper.js";

/**
 * Custom hook to handle the removal of an item from an order list.
 * 
 * @param {Array} orderItems - The list of items in the order.
 * @param {Function} setOrderItems - Function to set the list of items in the order.
 * @param {Function} setItem - Function to set a single item.
 * @param {Function} calculateBookingFee - Function to calculate the booking fee.
 * @param {Function} setBookingFee - Function to set the booking fee.
 * 
 * @return {Function} - A memoized function to handle the removal of an item from the order list.
 */
const useHandleRemoveOrderItem = () => {
  const { setOrderItems, basket, basketItems, setSelectedAddons, setBasket } = useEcommerce();
  const { closeDrawer } = useDrawer();

  return useCallback((e, old, itemToRemove) => {
    const orderItems = basketItems();

    console.log('itemToRmove', itemToRemove)

    // Filter order items that have dependencies
    const itemsWithDependencies = basket.orderItems.filter(item => item.dependencies && item.dependencies.length > 0);

    // Flag to indicate if an unremovable dependency is found
    let unremovableDependencyFound = false;

    // Item that cannot be removed due to dependency, initialized as null
    let dependentItem = null;

    // Iterate over each item with dependencies
    itemsWithDependencies.forEach(item => {
      // Find a dependency in the current item that matches the itemToRemove
      const dependency = item.dependencies.find(dep => dep.id === itemToRemove.id);

      // If such a dependency is found
      if (dependency) {
        // Set flag to true
        unremovableDependencyFound = true;
        // Store the item that has this unremovable dependency for the warning message
        dependentItem = item;
        // Exit the loop early since we've found an unremovable dependency
        return;
      }
    });

    // If an unremovable dependency is found, show a warning and exit the function
    // if (unremovableDependencyFound && dependentItem) {
    //   toast.warn(`${dependentItem.name} requires ${itemToRemove.name}`);
    //   return;
    // }

    // let updatedItems;

    // Check if itemToRemove is an addon by iterating over order items
    let isAddon = false;
    let updatedItems = orderItems.map(item => {
      if (item.addons && item.addons.length > 0) {
        const updatedAddons = item.addons.filter(addon => addon.id !== itemToRemove.id);
        if (updatedAddons.length !== item.addons.length) {
          isAddon = true;
          // Update the selectedAddons state
          setSelectedAddons(prev => prev.filter(selectedAddon => selectedAddon.id !== itemToRemove.id));
          return { ...item, addons: updatedAddons };
        }
      }
      return item;
    });

    // If itemToRemove is not an addon, filter it from the order items
    if (!isAddon) {
      updatedItems = orderItems.filter(item => item.id !== itemToRemove.id);
    }

    // If itemToRemove has dependencies, remove them as well
    if (itemToRemove.dependencies) {
      itemToRemove.dependencies.forEach(element => {
        updatedItems = updatedItems.filter(item => item.id !== element.id);
      });
    }


    /**
     * Update on the server
     */

    axios.post(`${API_URL}/basket/${basket.id}/remove-product/${itemToRemove.id}`)
        .then((res) => {
          console.log('res', res.data.basket)
          setBasket(res.data.basket);
        })
        .catch((err) => {
          console.error('error', err)
        });


    // Update the state for order items

    if (updatedItems.length === 0) closeDrawer();
  }, [basketItems, setOrderItems, closeDrawer]);
};


export default useHandleRemoveOrderItem
