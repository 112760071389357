import React from 'react';
import PropTypes from 'prop-types';
import { InfoSection, Paragraph, ShortTitle } from "../../../components/ui/typography/index.js";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100vh;
    gap: 20px;
`;

const InfoSectionContainer = styled.div``;

const HowWeDoIt = () => {
    const style = {
        container: {
            marginTop: '-80px',
        }
    };

    return (
        <Container>
            <ShortTitle title={'How'} subtitle={'We Do It'} style={style} />
            <InfoSectionContainer>
                <Paragraph style={{ marginBottom: '20px' }}>Our approach is the perfect storm of deep industry wisdom and bleeding-edge tech sorcery</Paragraph>
                <InfoSection subtitle={'Founding Team'} paragraph={'Forged in the crucible of 20+ years of friendship and industry battle scars\n'} />
                <InfoSection subtitle={''} paragraph={'Team DNA infused with event management, venue operations, and customer-centric genes'} />
                <InfoSection subtitle={'In-House Tech & Creatives'} paragraph={'Tech innovations birthed by our in-house team of digital alchemists'} />
            </InfoSectionContainer>
        </Container>
    );
};

HowWeDoIt.propTypes = {
    // components: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default HowWeDoIt;