import React, {useState} from 'react'
import {InfoItemComponent} from '../../../../components/ui/InfoItemComponent';
import {Select, StyledOption} from '../styles/basket.styles';
import {AiFillDelete} from 'react-icons/ai';
import {useHandleQuantityChange, useHandleRemoveOrderItem} from '../../js/hooks';
import {useEcommerce} from '../../js/context/EcommerceContext';
import {convertToCurrency} from '../../../../js/helpers/helpers';
import {Paragraph} from "../../../../components/ui/typography/index.js";
import styled from "styled-components";
import {DividerWithText} from "../../../../components/ui/LogoWall/DividerWithText.jsx";
import DepositOptions from "./DepositOptions.jsx";
import {productAcceptsDeposit} from "../js/utils/depositUtils.js";
import {orderItemSubTotal} from "../js/utils/basketUtils.js";
import {HowManyPeople} from "../../components/HowManyPeople/HowManyPeople.jsx";


const BasketItemContainer = styled.div`
    display: flex;
    place-items: center;
    flex-direction: column;
    justify-content: stretch;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
    border-radius: var(--border-radius-default);
    border: solid 1px rgba(255,255,255,0.1);
    box-shadow: 2px 5px 10px rgba(0,0,0,0.05), -7px -3px 20px rgba(0,0,0,0.06);
    padding: 10px;
    background: rgba(0, 0, 0, 0.05);
`

// const HowManyPeople = ({ setNumberOfPeople, numberOfPeople, handleNumberOfPeople, product }) => {
//
//     const { updateOrderItemValue } = useEcommerce();
//
//     useEffect(() => {
//         updateOrderItemValue(product.id, 'quantity', 2);
//     }, [])
//
//
//     const options = [];
//
//     for (let i = 2; i <= 10; i++) {
//         options.push(<option label={i + ' People'} key={i} value={i}>{i}</option>);
//     }
//
//     const style = {
//         background: 'rgba(0,0,0,0.08)',
//         padding: '10px',
//         borderRadius: 'var(--border-radius-default)',
//         width: '100%',
//         minHeight: '40px',
//         height: '40px',
//     }
//
//     return (
//             <Select
//                 aria-placeholder={'How many people?'}
//                 onChange={(e) => handleNumberOfPeople(e.target.value, product)}
//                 style={style}
//             >
//                 {options}
//             </Select>
//     )
// }
export const BasketItems = () => {
    const handleRemoveOrderItem = useHandleRemoveOrderItem();
    const handleQuantityChange = useHandleQuantityChange();
    const [numberOfPeople, setNumberOfPeople] = useState(2);
    const { basket, basketItems, updateOrderItemValue } = useEcommerce();

    const handleNumberOfPeople = (people, product) => {
        product.options = { ...product.options, numberOfGuests: people }
        updateOrderItemValue(product.id, 'quantity', people);
    }

    const handleClickDelete = (e, item) => {
        handleRemoveOrderItem(e, basket.orderItems, item)
    }

    return (
        basketItems().map((item, index) => {
            const remaining = item.remainingForTimeSlot || item.remaining;

            const quantityAvailable = remaining < item.maxPerOrder ? remaining : item.maxPerOrder;


            return (
                <BasketItemContainer id={'basket-container'} key={index}>
                    <BasketItemDetail
                        item={item}
                        handleQuantityChange={handleQuantityChange}
                        handleRemoveOrderItem={(e, item) => handleClickDelete(e, item)}
                        quantityAvailable={quantityAvailable}
                    />

                    {
                        item.category === 'tableBooking' && (
                            <>
                                <HowManyPeople
                                    setNumberOfPeople={setNumberOfPeople}
                                    numberOfPeople={numberOfPeople}
                                    handleNumberOfPeople={handleNumberOfPeople}
                                    product={item}
                                />
                                {/*<Divider/>*/}
                            </>
                        )
                    }


                    {item?.basketComment && (
                        <Paragraph style={{ opacity: '0.4', textAlign: 'left' }}>{item.basketComment}</Paragraph>
                    )}



                    {/* Display addons if available */}
                    {item?.addons?.length > 0 && item.addons.map((addon, addonIndex) => {
                        // const quantityAvailable = remaining < addon.maxPerOrder ? remaining : addon.maxPerOrder;

                        return (
                            <>
                                <BasketItemDetail
                                    key={`${index}-addon-${addonIndex}`}
                                    item={addon}
                                    handleQuantityChange={handleQuantityChange}
                                    handleRemoveOrderItem={(e, addon) => handleClickDelete(e, addon)}
                                    quantityAvailable={quantityAvailable}
                                />
                            </>


                        )}
                    )}

                    {productAcceptsDeposit(item) && (
                        <>
                            <DividerWithText text={'Deposit Options'} />
                            <DepositOptions product={item} />
                        </>
                    )}
                </BasketItemContainer>
            );
        })
    );
};


const BasketItemDetail = ({ item, handleQuantityChange, handleRemoveOrderItem, quantityAvailable }) => {

    if (!item) return;

    const chargePrice = orderItemSubTotal(item)

    console.log('quantity', quantityAvailable)

    return (
        <>
            <InfoItemComponent>

                    <Select value={item.quantity} onChange={(e) => handleQuantityChange(e, item)}>
                        {
                            quantityAvailable && Array.from({length: quantityAvailable}, (_, i) => {
                                const quantity = i + 1;

                                return (
                                    <StyledOption key={i} value={quantity}>{quantity}</StyledOption>
                                )
                            })
                        }
                    </Select>



                <p id='property'>{item?.name}</p>
                <p id={'price'}>{convertToCurrency(chargePrice)}</p>
                <AiFillDelete style={{cursor: 'pointer', color: 'var(--error-colour)'}}
                              onClick={(e) => handleRemoveOrderItem(e, item)}/>
            </InfoItemComponent>
            {/*<p style={{fontSize: '0.7rem'}}> {item.paymentOption === DEPOSIT_PAYMENT_OPTION && 'Pay ' + convertToCurrency(item.depositAmount * item.quantity) + ' deposit'}</p>*/}

        </>


    )
        ;
};

export default BasketItemDetail;
