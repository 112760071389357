import { useState } from "react";
import { ButtonComponent } from "../../../components/ui/buttons";
import { InstructionTitle } from "../../../components/ui/typography";
import { useDrawer } from "../../../js/hooks/context/DrawerContext";
import { StandardSection } from "../../../layout/sections/StandardSection";
import { eventData } from "../../events/js/eventData";
import PrimaryEventPage from "../../events/pages/PrimaryEventPage";
import styled from "styled-components";

const EventsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 auto;
    background-colour: red;

    @media (min-width: 768px) {
        max-width: 75%;
    }
`

const EventsSection = ({ events }) => {

    const [event, setEvent] = useState(null);
    const { openDrawer } = useDrawer();


    if(!events || events.length === 0) return null;

    return (
        <StandardSection>
            <EventsContainer>
                            <InstructionTitle title={'This Campaigns Events'} style={{marginBottom: '-20px', alignSelf: 'center'}} />
                            {events.map((event) => {

                                const { tickets, tables, djs_artists, sponsors } = eventData(event);
                                const handleClick = () => {

                                    setEvent(event)

                                    openDrawer(
                                        <PrimaryEventPage
                                            event={event}
                                            sponsors={sponsors}
                                            tables={tables}
                                            tickets={tickets}
                                            business={event.business}
                                            djs_artists={djs_artists}
                                        />)
                                }

                                return (
                                    <ButtonComponent
                                        key={event.id}
                                    
                                        variant={'outlined'}
                                        text={event.name}
                                        onClick={handleClick}
                                    />
                                );
                            })}
            </EventsContainer>
        </StandardSection>
    );
}

export default EventsSection;