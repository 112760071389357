import React, {useContext} from 'react';
import styled from "styled-components";
import {FullPageContainer} from "../../../../layout/styles/layoutStyles.js";
import {ImpactTitle, StyledTitle} from "../../../../components/ui/typography/index.js";
import {AuthContext} from "../../../authentication/index.js";
import {getUsersOrders} from "../../api/orders.js";
import {
    RectangleCardComponent
} from "../../../../components/ui/cards/RectangleCardComponent/RectangleCardComponent.jsx";
import {
    RectangleCardImage
} from "../../../../components/ui/cards/RectangleCardComponent/components/styles/rectangleCardImage.style.js";
import {useApiQuery} from "../../../../hooks/customHooks.js";
import {LoadingAnimationComponent} from "../../../../components/ui/LoadingAnimationComponent.jsx";
import {DividerWithText} from "../../../../components/ui/LogoWall/DividerWithText.jsx";
import {HorizontalFeaturedListLayout} from "../../../../layout/ListLayouts/HorizontalFeaturedListLayout.jsx";
import {LoyaltyCard} from "../../../cards/components/LoyaltyCard/LoyaltyCard.jsx";
import {data} from "autoprefixer";
import {IoPencilSharp, IoSettingsSharp} from "react-icons/io5";
import {useDrawer} from "../../../../js/hooks/context/DrawerContext.jsx";
import UserDetailsForm from "../../../authentication/components/UserDetailsForm.jsx";
import {Order} from "../../../ecommerce/orders/components/index.js";
import {useOrder} from "../../../ecommerce/orders/hooks/OrderContext.jsx";
import CardholderPage from "../../../cards/pages/CardholderPage.jsx";

const Container = styled.section`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 0px;
    min-width: 100%;
    
`

const Section = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;
`

const QuickActionsContainer = styled.section`
    height: fit-content;
`

const UserHomePage = () => {

    const { user, isAuthenticated } = useContext(AuthContext)

    if(!user || !isAuthenticated) return;


    return (
        <Container id={'user-area'}>
            <ImpactTitle main={`${user.first_name}`} header={'Welcome Back'} />

            <QuickActionsSection/>
            <CardSection/>
            <OrderSection/>

        </Container>
    );
};

const QuickActionsSection = () => {
    const { openDrawer } = useDrawer();

    return (
        <Section id={'quick-actions'}>
            <DividerWithText text={'Quick Actions'} />
            <QuickActionsContainer>
            <IoSettingsSharp color={'var(--colour-light)'} size={'1.5rem'} onClick={() => openDrawer(<UserDetailsForm />)} />
            </QuickActionsContainer>
        </Section>

    )
}


const OrderSection = () => {
    const {openDrawer} = useDrawer();
    const { setOrder } = useOrder();

    const orders = useApiQuery(`user/orders?limit=4`)

    if(orders?.isLoading) return;

    if(orders?.data?.data?.length === 0) return;

    const handleClickOrder = (order) => {
        setOrder(order)
        openDrawer(<Order />)
    }

    return (
        <Section id={'users-orders'}>
            <DividerWithText text={'Recent Orders'}/>


            {orders.data && orders.data.data.slice(0,4).map((item, index) => {

                return (
                    <RectangleCardComponent
                        key={index}
                        image={<RectangleCardImage source={item.image}  />}
                        title={`${item.name} - ${item?.event?.name}`}
                        description={`Order Total: ${item.amount}`}
                        onClick={() => handleClickOrder(item)}
                    />
                )
            })}
        </Section>
    )
}

const CardSection = () => {

    const {openDrawer} = useDrawer();
    const cards = useApiQuery('user/cards?limit=4');

    if(cards?.isLoading) return;


    if(cards?.data?.data?.length === 0) return;

    const handleClickCard = (card) => {
        openDrawer(<CardholderPage cardholder={card} />)
    }

    return (
        <Section id={'users-cards'} style={{padding: '20px'}}>
            <DividerWithText text={'Your Cards'} />
            {cards.data && cards.data.data.slice(0,4).map((cardholder, index) => {

                let style;

                if(index !== 0)  {
                    style = {
                        marginTop: '-150px'
                    }
                }

                return (
                    // eslint-disable-next-line react/jsx-key
                    <LoyaltyCard
                        tier={cardholder.card_tier}
                        style={style}
                        card={cardholder.card_tier.card}
                        key={index}
                        onClick={() => handleClickCard(cardholder)}
                    />
                )
            })}
        </Section>
    )
}

export default UserHomePage;