import styled from "styled-components"

export const CardContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--small);
    max-width: 400px;
    height: fit-content;
    max-height: 130px;
    /* margin-top: 12px; */
    margin-bottom: 12px;
    overflow: hidden;
    background-color: var(--faded-black);
    border-top-left-radius: var(--border-radius-default);
    border-bottom-left-radius: var(--border-radius-default);
    border-radius: 10px;
    z-index: 2;
    box-shadow: rgba(10, 10, 10, 0.3) 0px 8px 24px;
    animation:  fadeIn 450ms ease-in-out;
    transition: box-shadow 550ms ease-in-out;
    backdrop-filter: blur(10px);
    background-image:  linear-gradient(to right, var(--default-primary-colour), rgba(255, 0, 0, 0));
    

    &:hover {
        cursor: pointer;
        transition: box-shadow 350ms ease-in-out;
        box-shadow: var(--shadow-hover-default)
       
    }
    
   

    @media screen and (min-width: 1024px){
        width: 600px;
        max-width: var(--laptop); 
    }
    
    @media screen and (min-width: 1920px){
        min-width: var(--desktop);
  
    }
`
export const CardInfo = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0px;
    flex-grow: 3;
    padding: 10px;
    box-sizing: border-box;
    color: white;

    h3 {
        margin: 0;
        font-size: 1em;
        line-height: 1em;
        color: var(--default-accent-colour)
    }
    
    p {
        font-size: 0.8em;
        line-height: 1em;
        margin-top: 5px;
        text-transform: capitalize;
        
        @media screen and (min-width: 1024px){
            font-size: 0.8em;
        }
    }
    
    `
export const CardTitle = styled.h3`
    border-bottom: ${props => props.showIcons == false ? 'solid 0.5px rgba(255,255,255,0.1)' : ''};
    padding-bottom: ${props => props.showIcons == false ? `7px` : ''};
`