import React from 'react'
import styled from 'styled-components';
import { LoyaltyCard } from '../components/LoyaltyCard/LoyaltyCard';
import { imagePath, setSessionStorageJSON } from '../../../js/Helper';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StandardSection } from '../../../layout/sections/StandardSection';
import { StyledTitle } from '../../../components/ui/typography/StyledTitle';
import queryString from 'query-string';
import { Title } from '../../../components/ui/typography';
import { LargePill } from '../../../components/ui/Pills/LargePill' 
import { FadedBgImage } from '../../../components/ui/FadedBgImage';
import { ButtonComponent } from '../../../components/ui/buttons';


const DefaultCard = ({ card, activeTier, setActiveTier }) => {

  const { tiers, default_tier } = card
  const activeTheme = activeTier.theme
  

  // hooks 
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // get any search queries that are in the url
  const searchParams = queryString.parse(window.location.search);

  // get the slug from the url
  const slug = params.slug;

  const headerStyle = {
    color: activeTier.theme?.accent_colour,
  }

  const textStyle = {
    color: activeTier.theme?.text_colour,
  }


  const activeThemeStyle = {
    activeTheme,
    headerStyle,
    textStyle
  }

  localStorage.setItem('activeThemeStyle', activeThemeStyle)

  const cardComponent = <LoyaltyCard card={card} tier={activeTier} theme={activeTheme}  />
  const bodyEl = document.querySelector('body')

  bodyEl.style.backgroundColor = activeTheme?.primary_colour
  bodyEl.style.backgroundImage =`url(${activeTier?.image?.cdnUrl})`

  // functions 

  const handleTierChange = (tier) => {
    setActiveTier(tier)
  }

   // put the card and tier details in to local storage
  // for the next page to use
  const handleClick = () => {
    
    setSessionStorageJSON(`card`, card);
    setSessionStorageJSON(`tier`, activeTier);
    setSessionStorageJSON(`theme`, activeTheme)

    navigate(`/card/${card.slug}/${activeTier.id}/signup${location.search}`)
     
  }

   return (
    <PageContainer>
  
     { innerWidth < 800 && <FadedBgImage tier={activeTier} image={activeTier?.image?.cdnUrl}/>} 


      <div id="left" style={{backgroundColor:activeTier.theme?.primary_colour}}>
        
        <StandardSection style={{backgroundColor:'transparent'}}>
          { innerWidth < 1200 && cardComponent }
          <StyledTitle title={card.name} subtitle={tiers.length > 1 && activeTier.name} theme={activeTier.theme}  />
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo atque hic id obcaecati vitae aliquid reprehenderit dolor error quae corrupti.</p>
        </StandardSection>

        {
          tiers.length > 1 &&
          <TiersContainer>
            {
              tiers && tiers.map((tier, index) => {
              
                return (
                  <Tier onClick={() => setActiveTier(tier)} tierId={tier.id} activeTierId={activeTier.id}>{tier.name}</Tier>
                )
              })
            }
          </TiersContainer>
        }

        <div dangerouslySetInnerHTML={{ __html: activeTier?.description }} />
        <section className="flex flex_center flex_column">

          
        { 
          activeTier.benefits && activeTier.benefits.length > 0 && ( 
          <>
            <Title text="Benefits" theme={activeTier.theme}></Title>
            <div className="two_column_grid" style={{marginTop:'1rem'}}>
              {
                activeTier.benefits.map((benefit, index) => {
                  return (
                    <LargePill key={benefit.id} text={benefit.title} theme={activeTier.theme} />
                  )
                })
              }
            </div>
          </>
        )}


        </section>

{
          activeTier.locations  && activeTier.locations.length > 0 &&  (
            <>
            <section className="flex flex_center flex_column">
              <Title text="Locations" theme={activeTier.theme}></Title>
              <div className="two_column_grid" style={{marginTop:'1rem'}}>
                {
                  activeTier.locations.map((location) => {
                  return (
                    <LargePill key={location.location.id} text={location.location.business.name} theme={activeTier.theme} />
                  )
                  })
                }
              </div>
            </section>
            </>
          )
        }    

        <ButtonComponent onClick={handleClick} text="Signup Now" page={`/card/${slug}/${activeTier.id}/signup`} theme={activeTier.theme} showBack={searchParams.back} />
        </div>


        <div id="right" style={{backgroundColor: activeTheme?.background_colour}}>

          { innerWidth > 1200 && cardComponent}

    
      </div>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  padding-bottom: 150px;
  background-color: var(--primary-colour);
`

const TiersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Tier = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--small);
  height: 45px;
  border-radius: var(--border-radius-small);
  background-color: ${props => props.tierId == props.activeTierId ? 'var(--accent-colour)' : 'rgba(255,255,255,0.05)'};
  border: solid 1px var(--accent-colour);
  font-size: larger;
  font-weight: bold;
`

export default DefaultCard;